import { gql } from 'apollo-boost';

export const DNI_TYPES = gql`
  query DniTypes($type: String) {
    dniTypes(type: $type) {
      key
      description
    }
  }
`;
