const error = {
  categories: {
    authentication: 'authentication',
    authorization: 'authorization',
    entityNotFound: 'entityNotFound',
    general: 'general',
    internal: 'internal',
    validation: 'validation',
  },
  levels: {
    warning: 'warning',
    error: 'error',
    success: 'success',
    info: 'info',
  },
};

export default error;
