import React, { forwardRef } from 'react';
import { InputNumber } from 'antd';
import * as PropTypes from 'prop-types';
import { decimalFormatter } from '../../utils/input';
import {
  decimalSeparator,
} from '../../config/Shared/Constants/general';

const InputDecimal = forwardRef(({ ...props }, ref) => {
  const overrideProps = {
    ...props,
    ref,
    decimalSeparator,
    step: '1',
    formatter: decimalFormatter,
  };

  return <InputNumber {...overrideProps} />;
});

InputDecimal.propTypes = {
  precision: PropTypes.number,
};

export default InputDecimal;
