import { gql } from 'apollo-boost';

export const COUNTRIES = gql`
  query Countries {
    countries {
      id
      name
    }
  }
`;
