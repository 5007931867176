import React from 'react';
import * as PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import ResultTitle from './ResultTitle';

const ContainerTitle = ({ type, children }) => (
  <Row type="flex">
    <Col span={4} className="custom-container-indicator">
      <Row type="flex" className="custom-container-indicator-title">
        <Col span={24}>
          <ResultTitle type={type} />
        </Col>
      </Row>
    </Col>
    <Col span={20}>{children}</Col>
  </Row>
);

ContainerTitle.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
};

export default ContainerTitle;
