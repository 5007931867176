import { lazy } from 'react';
import create from './create';
import asyncFiles from './asyncFiles';

const routes = [
  create({
    layout: false,
    url: '/forgotPassword',
    component: asyncFiles.forgotPassword,
  }),
  create({
    layout: undefined,
    url: '/bar',
    component: lazy(() => import('../../../pages/Reports/BarExample.js')),
  }),
  create({
    layout: false,
    url: '/login',
    component: asyncFiles.login,
  }),
  create({
    layout: false,
    url: '/register',
    component: asyncFiles.register,
  }),
  create({
    layout: false,
    url: '/activate/:token?',
    component: asyncFiles.activate,
  }),
  create({
    layout: false,
    url: '/reset/:token?',
    component: asyncFiles.reset,
  }),
  create({
    layout: undefined,
    url: '/ivc/signCharters/:token?',
    component: asyncFiles.signCharters,
  }),
  create({
    layout: undefined,
    url: '/result/:type?/:who?',
    component: asyncFiles.result,
  }),
  create({
    layout: true,
    url: '/home/:company?',
    component: asyncFiles.home,
    target: 'home',
  }),
  create({
    layout: true,
    url: '/profile/:company?',
    component: asyncFiles.profile,
    target: 'profile',
  }),
  create({
    layout: true,
    url: '/companyRequest/:company?/:id?/:edit?',
    component: asyncFiles.companyRequest,
    target: 'companies',
  }),
  create({
    layout: true,
    url: '/companies/:company?',
    component: asyncFiles.companies,
    target: 'companies',
  }),
  create({
    layout: true,
    url: '/records/:company?',
    component: asyncFiles.records,
    target: 'records',
  }),
  create({
    layout: true,
    url: '/reports/:company?',
    component: asyncFiles.reports,
    target: 'reports',
  }),
  create({
    layout: false,
    url: '/publicReports',
    component: asyncFiles.publicReports,
  }),
  create({
    layout: true,
    url: '/company/:company?/:id?',
    component: asyncFiles.company,
    target: 'companies',
  }),
  create({
    layout: true,
    url: '/productRequest/:company?/:id?/:edit?',
    component: asyncFiles.productRequest,
    target: 'products',
  }),
  create({
    layout: true,
    url: '/products/:company?',
    component: asyncFiles.products,
    target: 'products',
  }),
  create({
    layout: true,
    url: '/product/:company?/:id?',
    component: asyncFiles.product,
    target: 'products',
  }),
  create({
    layout: true,
    url: '/users/:company?/:id?',
    component: asyncFiles.users,
    target: 'users',
  }),
  create({
    layout: undefined,
    url: '/maintenance/:message?',
    component: asyncFiles.maintenance,
  }),
  create({
    layout: true,
    url: '/roles/:company?',
    component: asyncFiles.roles,
    target: 'roles',
  }),
  create({
    layout: true,
    url: '/role/:company?/:id?',
    component: asyncFiles.role,
    target: 'roles',
  }),
  create({
    layout: true,
    url: '/myRequests/:company?',
    component: asyncFiles.myRequests,
    target: 'myRequests',
  }),
  create({
    layout: true,
    url: '/importers/:company?/:id?',
    component: asyncFiles.importers,
    target: 'importers',
  }),
  create({
    layout: true,
    url: '/deleteImporters/:company?/:id?',
    component: asyncFiles.deleteImporters,
    target: 'deleteImporters',
  }),
  create({
    layout: true,
    url: '/importerRequest/:company?/:id?/:product?/:edit?',
    component: asyncFiles.importerRequest,
    target: 'importers',
  }),
  create({
    layout: true,
    url: '/deleteImporterRequest/:company?/:id?/:product?',
    component: asyncFiles.deleteImporterRequest,
    target: 'importer-deletion\t',
  }),
  create({
    layout: true,
    url: '/deleteImporterReview/:company?/:id?/:product?',
    component: asyncFiles.deleteImporterReview,
    target: 'importer-deletion\t',
  }),
  create({
    layout: true,
    url: '/importer/:company?/:id?/:product?',
    component: asyncFiles.importer,
    target: 'importers',
  }),
  create({
    layout: true,
    url: '/roles/:company?',
    component: asyncFiles.roles,
    target: 'roles',
  }),
  create({
    layout: true,
    url: '/role/:company?/:id?',
    component: asyncFiles.role,
    target: 'roles',
  }),
  create({
    layout: true,
    url: '/user/:company?/:id?/',
    component: asyncFiles.user,
    target: 'users',
  }),
  create({
    layout: true,
    url: '/agent/:company?/:id?',
    component: asyncFiles.agent,
    target: 'agents',
  }),
  create({
    layout: true,
    url: '/agents/:company?',
    component: asyncFiles.agents,
    target: 'agents',
  }),
  create({
    layout: true,
    url: '/importerReview/:company?/:id?/:product?/:edit?',
    component: asyncFiles.importerReview,
    target: 'importers',
  }),
  create({
    layout: true,
    url: '/checkoutRequest/:company?/:id?',
    component: asyncFiles.checkoutRequest,
    target: 'payments',
  }),
  create({
    layout: true,
    url: '/checkoutResult/:company?/:id?',
    component: asyncFiles.checkoutResult,
  }),
  create({
    layout: true,
    url: '/productReview/:company?/:id?',
    component: asyncFiles.productReview,
    target: 'products',
  }),
  create({
    layout: true,
    url: '/productAdviser/:company?/:id?',
    component: asyncFiles.productAdviser,
    target: 'products',
  }),
  create({
    layout: true,
    url: '/menu/:company?/:id?',
    component: asyncFiles.menu,
    target: 'menus',
  }),
  create({
    layout: true,
    url: '/menus/:company?',
    component: asyncFiles.menus,
    target: 'menus',
  }),
  create({
    layout: true,
    url: '/payments/:company?',
    component: asyncFiles.payments,
    target: 'payments',
  }),
  create({
    layout: true,
    url: '/certificates/:company?',
    component: asyncFiles.certificates,
    target: 'certificates',
  }),
  create({
    layout: true,
    url: '/freeSaleRequest/:company?/:id?',
    component: asyncFiles.freeSaleRequest,
    target: 'certificates',
  }),
  create({
    layout: true,
    url: '/freeSaleReview/:company?/:id?',
    component: asyncFiles.freeSaleReview,
    target: 'certificates',
  }),
  create({
    layout: true,
    url: '/noRegisterRequest/:company?/:id?',
    component: asyncFiles.noRegisterRequest,
    target: 'certificates',
  }),
  create({
    layout: true,
    url: '/noRegisterReview/:company?/:id?',
    component: asyncFiles.noRegisterReview,
    target: 'certificates',
  }),
  create({
    layout: true,
    url: '/freeSalePlantsRequest/:company?/:id?',
    component: asyncFiles.freeSalePlantsRequest,
    target: 'certificates',
  }),
  create({
    layout: true,
    url: '/freeSalePlantsReview/:company?/:id?',
    component: asyncFiles.freeSalePlantsReview,
    target: 'certificates',
  }),
  create({
    layout: true,
    url: '/productExhaustion/:company?/:id?',
    component: asyncFiles.productExhaustion,
    target: 'exhaustion',
  }),
  create({
    layout: true,
    url: '/exhaustionReview/:company?/:id?',
    component: asyncFiles.exhaustionReview,
    target: 'exhaustion',
  }),
  create({
    layout: true,
    url: '/exhausts/:company?',
    component: asyncFiles.exhausts,
    target: 'exhaustion',
  }),
  create({
    layout: true,
    url: '/composition/:company?/:tab?',
    component: asyncFiles.composition,
    target: 'nutrients',
  }),
  create({
    layout: true,
    url: '/nutrient/:company?/:id?',
    component: asyncFiles.nutrient,
    target: 'nutrients',
  }),
  create({
    layout: true,
    url: '/ingredient/:company?/:id?',
    component: asyncFiles.ingredient,
    target: 'nutrients',
  }),
  create({
    layout: true,
    url: '/species/:company?',
    component: asyncFiles.species,
    target: 'species',
  }),
  create({
    layout: true,
    url: '/ownerships/:company?',
    component: asyncFiles.ownerships,
    target: 'transfer-ownership',
  }),
  create({
    layout: true,
    url: '/ownership/:company?/:id?/:product?',
    component: asyncFiles.ownership,
    target: 'transfer-ownership',
  }),
  create({
    layout: true,
    url: '/ownershipReview/:company?/:id?/:product?',
    component: asyncFiles.ownershipReview,
    target: 'transfer-ownership',
  }),
  create({
    layout: true,
    url: '/extensionReview/:company?/:id?/:product?',
    component: asyncFiles.extensionReview,
    target: 'transfer-ownership',
  }),
  create({
    layout: true,
    url: '/adminChecklists',
    component: asyncFiles.adminChecklists,
    target: 'checklists',
  }),
  create({
    layout: true,
    url: '/adminChecklist/:id?',
    component: asyncFiles.adminChecklist,
    target: 'checklists',
  }),
  create({
    layout: true,
    url: '/adminVisits/:sectional?',
    component: asyncFiles.adminVisits,
    target: 'visitsSchedules',
  }),
  create({
    layout: true,
    url: '/adminMonth/:id?',
    component: asyncFiles.adminMonth,
    target: 'visitsSchedules',
  }),
  create({
    layout: true,
    url: '/adminCreateVisit/:month?/:plant?',
    component: asyncFiles.adminCreateVisit,
    target: 'visitSchedules'
  }),
  create({
    layout: true,
    url: '/adminEditVisit/:id?',
    component: asyncFiles.adminEditVisit,
    target: 'visitSchedules'
  }),
  create({
    layout: true,
    url: '/visitMyVisits',
    component: asyncFiles.visitMyVisits,
    target: 'visits'
  }),
  create({
    layout: true,
    url: '/visit/execute/:id?',
    component: asyncFiles.executeVisit,
    target: 'visits'
  }),
  create({
    layout: true,
    url: '/visit/checklistBpmaa/:id?/:visitId?',
    component: asyncFiles.checklistBpmaa,
    target: 'visits'
  }),
  create({
    layout: true,
    url: '/visit/checklistLabeled/:id?/:visitId?',
    component: asyncFiles.checklistLabeled,
    target: 'visits'
  }),
  create({
    layout: true,
    url: '/visit/SanityMeasure/:id?/:visitId?',
    component: asyncFiles.sanityMeasure,
    target: 'visits'
  }),
  create({
    layout: true,
    url: '/visit/sampling/:id?/:visitId?',
    component: asyncFiles.sampling,
    target: 'visits'
  }),
  create({
    layout: true,
    url: '/visit/manualSigning/:id?',
    component: asyncFiles.manualSigning,
    target: 'visits'
  }),
  create({
    layout: true,
    url: '/visit/:id?',
    component: asyncFiles.visit,
    target: 'visits'
  }),
  create({
    layout: true,
    url: '/suggestedSchedules/:sectional?',
    component: asyncFiles.suggestedSchedules,
    target: 'suggestedSchedule'
  }),
  create({
    layout: true,
    url: '/suggestedSchedule/:id?',
    component: asyncFiles.suggestedSchedule,
    target: 'suggestedSchedule'
  }),
  create({
    layout: true,
    url: '/ivcTemplates',
    component: asyncFiles.ivcTemplates,
    target: 'IVCTemplates'
  }),
  create({
    layout: true,
    url: '/searchFilesIvc',
    component: asyncFiles.searchFilesIvc,
    target: 'searchFilesIvc'
  }),
  create({
    layout: true,
    url: '/informationIvc/:company?',
    component: asyncFiles.ivcInformation,
    target: 'informationIvc'
  }),
  create({
    layout: true,
    url: '/information/SanityMeasure/:id?',
    component: asyncFiles.viewSanitaryMeasure,
    target: 'informationIvc'
  }),
  create({
    layout: true,
    url: '/information/visit/SanityMeasure/:id?',
    exact: true,
    component: asyncFiles.viewSanitaryMeasureVisit,
    target: 'informationIvc'
  }),
  create({
    layout: true,
    url: '/information/detail/visit/:id?',
    exact: true,
    component: asyncFiles.viewDetailVisit,
    target: 'informationIvc'
  }),
  create({
    layout: true,
    url: '/sanitaryMeasures/:company?',
    exact: true,
    component: asyncFiles.sanitaryMeasureDetail,
    target: 'sanitaryMeasures'
  }),
  create({
    layout: true,
    url: '/sanitaryMeasures/viewSanityMeasure/:id?',
    exact: true,
    component: asyncFiles.viewSanitaryMeasureDetail,
    target: 'sanitaryMeasures'
  }),
  create({
    layout: true,
    url: '/viewSample/:company?',
    exact: true,
    component: asyncFiles.viewSample,
    target: 'samples'
  }),
  create({
    layout: true,
    url: '/viewSample/detail/:id?',
    exact: true,
    component: asyncFiles.viewSampleDetail,
    target: 'samples'
  }),
  create({
    layout: false,
    url: '/searchResolution',
    component: asyncFiles.searchResolution,
  }),
  create({
    layout: true,
    url: '/signatureRequest',
    component: asyncFiles.signatureRequest,
    target: 'signature'
  }),
  create({
    layout: true,
    url: '/signatureRequestList',
    component: asyncFiles.signatureRequestList,
    target: 'signatureReques'
  }),
  create({
    layout: false,
    url: '/searchVerificationCode',
    component: asyncFiles.searchVerificationCode,
  }),
  // Must be always at the end
  create({
    layout: false,
    url: undefined,
    component: asyncFiles.notFound,
  }),
];

export default routes;
