import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Col, Divider, Form, Row, Switch
} from 'antd';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Files from './Files';
import { files } from '../../config/Shared/Constants';
import { UPLOAD } from '../../graphql';
import { notify } from '../../utils';
import Title from './Title';

const OldProduct = ({
  form,
  readonly,
  text,
  isProduct,
  isAntique,
  handleAntique,
  handleChange,
  children,
  dragText,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const handleUpload = async ({ file, onSuccess }) => {
    if (file.type === files.pdf) {
      if (
        file.size
        <= parseInt(process.env.REACT_APP_MAX_FILE_SIZE) * 1048576
      ) {
        const { upload } = (
          await client.mutate({ mutation: UPLOAD, variables: { upload: file } })
        ).data;
        onSuccess('ok');
        handleChange(upload);
      } else {
        notify({ message: t('rules.fileMaxSize'), type: 'warning' });
      }
    } else {
      notify({ message: t('rules.pdfOnly'), type: 'warning' });
    }
  };

  return (
    <Row>
      <Row type="flex" justify="center">
        {!isProduct ? (
          <Col span={24}>
            <Form.Item
              label={text}
              wrapperCol={{ xl: 8, m: 12 }}
              labelCol={{ xl: 16, m: 12 }}
              colon={false}
            >
              {form.getFieldDecorator('old', {
                initialValue: isAntique,
                valuePropName: 'checked',
              })(
                <Switch
                  disabled={readonly}
                  onChange={handleAntique}
                  checkedChildren={t('common.yes')}
                  unCheckedChildren={t('common.no')}
                />
              )}
            </Form.Item>
            <Divider className="custom-divider" />
          </Col>
        ) : (
          <Col span={24}>
            <Title title={t('company.oldDescription')} />
          </Col>
        )}
      </Row>
      <Row type="flex" justify="center">
        {isAntique && (
          <>
            {!readonly && (
              <>
                <Col span={16}>
                  <Files
                    title={dragText}
                    files={[]}
                    handleUpload={handleUpload}
                    readonly={readonly}
                  />
                </Col>
                <Divider className="custom-divider" />
              </>
            )}

            <Col span={24}>{children}</Col>
          </>
        )}
      </Row>
    </Row>
  );
};

OldProduct.propTypes = {
  isAntique: PropTypes.bool,
  isProduct: PropTypes.bool,
  readonly: PropTypes.bool,
  text: PropTypes.string,
  form: PropTypes.object,
  handleAntique: PropTypes.func,
  handleChange: PropTypes.func,
  children: PropTypes.object,
  dragText: PropTypes.string,
};

export default Form.create({ name: 'OldProduct' })(OldProduct);
