import { gql } from 'apollo-boost';

export const ELABORATION_TYPES = gql`
  {
    elaborationTypes {
      id
      name
      description
    }
  }
`;
