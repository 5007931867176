import React from 'react';
import * as PropTypes from 'prop-types';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import Container from './Container';
import Title from './Title';

const StatusBar = ({
  title, percent, status, children
}) => {
  const { t } = useTranslation();

  return (
    <Container className="custom-status-bar-container">
      <Title title={title || t('statusBar.percent')} />
      <Progress percent={percent} status={status} />
      {children}
    </Container>
  );
};

StatusBar.propTypes = {
  percent: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.string,
  children: PropTypes.any,
};

export default StatusBar;
