import React from 'react';
import * as PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import inlineStyles from './inlineStyles';
import { ToolbarButton } from '../../../components/Shared';

const InlineStyleControls = ({ state, readonly, handleToggle }) => {
  const { t } = useTranslation();

  const currentStyle = state?.getCurrentInlineStyle();

  return (
    <div className="richEditor-controls" key="inline">
      {inlineStyles(t).map(({
        label, style, icon, divider
      }) => (
        (!divider
        && (
        <ToolbarButton
          key={label}
          active={currentStyle?.has(style)}
          label={label}
          icon={icon}
          readonly={readonly}
          handleToggle={() => handleToggle(style)}
          apply={style}
        />
        ))
        || <Divider key={label} type="vertical" />
      ))}
    </div>
  );
};

InlineStyleControls.propTypes = {
  readonly: PropTypes.bool,
  state: PropTypes.object,
  handleToggle: PropTypes.func
};

export default InlineStyleControls;
