import React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import HeaderUser from './HeaderUser';
import { LOGOUT } from '../../../graphql';

const User = ({ history, user, ...rest }) => {
  const client = useApolloClient();

  const handleLogout = async () => {
    await client.mutate({ mutation: LOGOUT });
    history.push('/');
  };

  return <HeaderUser user={user} handleLogout={handleLogout} {...rest} />;
};

User.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
};

export default withRouter(User);
