import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  Divider, Empty, Form, Input, Modal
} from 'antd';
import { useTranslation } from 'react-i18next';
import Friends from './Friends';
import { ACCEPT_COMPANY_RELATION, COMPANY_RELATIONS, REJECT_COMPANY_RELATION, } from '../../../graphql';
import { Loading } from '../../Shared';
import { notify } from '../../../utils';
import { formValidations } from '../../../config/Shared/Constants';

const FriendsRequest = ({ updateCounter, form }) => {
  const [modal, setModal] = useState({ visible: false, id: undefined });

  const { loading, data, refetch } = useQuery(COMPANY_RELATIONS, {
    variables: { status: 'pending' },
    fetchPolicy: 'network-only',
  });
  const client = useApolloClient();
  const { t } = useTranslation();

  if (loading) {
    updateCounter();
  }

  const handleCancel = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        const { data: canceled } = await client.mutate({
          mutation: REJECT_COMPANY_RELATION,
          variables: { id: modal.id, comment: values.reasons },
        });
        if (canceled?.rejectCompanyRelation) {
          notify({ message: t('friends.rejected'), placement: 'topLeft' });
          setModal({ visible: false, id: undefined });
        }
        await refetch();
        await updateCounter();
      }
    });
  };

  const handleOk = async (id) => {
    const { data: accepted } = await client.mutate({
      mutation: ACCEPT_COMPANY_RELATION,
      variables: { id },
    });
    if (accepted?.acceptCompanyRelation) {
      notify({ message: t('friends.accepted'), placement: 'topLeft' });
      setModal({ visible: false, id: undefined });
    }
    await refetch();
    await updateCounter();
  };

  return (
    <Form>
      <Loading loading={loading} custom="custom-component-spin">
        {data?.me.companyRelations?.length === 0 ? (
          <Empty />
        ) : (
          data?.me.companyRelations?.map(
            ({
              id, company, adviser, role
            }, index) => index < 3 && (
            <div key={index}>
              <Friends
                text={`${t('friends.companyRequest').replace(
                  '[{companyName}]',
                      company?.name
                )} ${
                  adviser
                    ? `${t('role.adviser')} ${
                      role ? `${t('friends.and')} ${role.name}` : ''
                    }`
                    : role?.name
                }`}
                handleOk={() => handleOk(id)}
                handleCancel={() => setModal({ visible: true, id })}
              />
              {index < data.me.companyRelations.length - 1 && <Divider />}
            </div>
            )
          )
        )}
      </Loading>
      <Modal
        visible={modal.visible}
        title={t('friends.reject')}
        okText={t('friends.reject')}
        cancelText={t('common.cancel')}
        onOk={handleCancel}
        onCancel={() => setModal({ visible: false, id: undefined })}
        afterClose={form.resetFields}
      >
        <div>
          {t('friends.rejectHelp')}
          <Form.Item label={t('friends.reasons')}>
            {form.getFieldDecorator('reasons', {
              initialValue: '',
              rules: [
                { required: true, message: t('rules.reasonsRequired') },
                formValidations(t).rules.max.textArea,
              ],
            })(<Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}
          </Form.Item>
        </div>
      </Modal>
    </Form>
  );
};

FriendsRequest.propTypes = {
  form: PropTypes.object,
  updateCounter: PropTypes.func,
};

export default Form.create({ name: 'friends-request' })(FriendsRequest);
