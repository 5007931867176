import { gql } from 'apollo-boost';

const userFields = `
  id
  dniType
  dni
  firstName
  lastName
  email
  address
  city {
    id
    state {
      id
    }
  }
  telephone
  cellphone
  isTech
  countryId
  cityName
  dniTypeName
  professionId
  professionKey
`;

export const ACCEPT_COMPANY_RELATION = gql`
  mutation AcceptCompanyRelation($id: ID!) {
    acceptCompanyRelation(id: $id)
  }
`;

export const ADD_COMPANY_USER = gql`
  mutation AddCompanyUser($input: CompanyUserInput!) {
    addCompanyUser(input: $input) {
      id
    }
  }
`;

export const AGENT = gql`
  query Agent($id: ID!) {
    agent(id: $id) {
      id
      dniType
      dni
      firstName
      lastName
      email
      roleId
      status
      sectional {
        id
        name
      }
    }
  }
`;

export const BEFORE_REFRESH = gql`
  mutation BeforeRefresh($refreshing: Int) {
    beforeRefresh(refreshing: $refreshing) @client
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String, $newPassword: String) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`;

export const CHECK_AUTHENTICATION = gql`
  query CheckAuthentication {
    checkAuthentication @client {
      logged
      user {
        id
        firstName
        lastName
        type
      }
      scope {
        companyId
      }
    }
  }
`;

export const CHECK_LOGIN = gql`
  query CheckLogin {
    checkLogin
    unix
    checkAuthentication @client {
      logged
      refresh
      token
      user {
        id
        firstName
        lastName
      }
      scope {
        companyId
      }
    }
    me {
      id
      pendingsCount {
        companyRelations
      }
    }
  }
`;

export const CHECK_REFRESH = gql`
  query CheckRefresh($token: String, $refreshing: Int, $current: Int) {
    checkRefresh(token: $token, refreshing: $refreshing, current: $current)
      @client
  }
`;

export const CHECK_REPRESENTATIVE = gql`
  query CheckRepresentative($companyId: ID!) {
    checkRepresentative(companyId: $companyId) {
      id
      dniType
      dni
      firstName
      lastName
      email
      address
      city {
        id
        state {
          id
        }
      }
      telephone
      cellphone
    }
  }
`;

export const COMPANY_RELATIONS = gql`
  query Me($status: String) {
    me {
      id
      companyRelations(status: $status) {
        id
        status
        adviser
        createdAt
        company {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
  }
`;

export const COMPANY_USER = gql`
  query CompanyUser($id: ID!) {
    companyUser(id: $id) {
      id
      dniType
      dni
      firstName
      lastName
      email
      user {
        dniType
        dni
        firstName
        lastName
        email
      }
      roleId
    }
  }
`;

export const CREATE_AGENT = gql`
  mutation CreateAgent($input: InputCreateAgent!) {
    createAgent(input: $input) {
      id
    }
  }
`;

export const CREATE_COMPANY_USER = gql`
  mutation CreateCompanyUser($input: CreateCompanyUserInput!) {
    createCompanyUser(input: $input) {
      id
    }
  }
`;

export const DELETE_COMPANY_USER = gql`
  mutation DeleteCompanyUser($id: ID!) {
    deleteCompanyUser(id: $id)
  }
`;

export const EDIT_AGENT = gql`
  mutation EditAgent($id: ID!, $input: InputEditAgent!) {
    editAgent(id: $id, input: $input) {
      id
    }
  }
`;

export const EDIT_COMPANY_USER = gql`
  mutation EditCompanyUser($id: ID!, $input: EditCompanyUserInput!) {
    editCompanyUser(id: $id, input: $input) {
      id
    }
  }
`;

export const EDIT_USER = gql`
  mutation EditUser($id: ID!, $input: InputEditUser) {
    editUser(id: $id, input: $input) {
      id
    }
  }
`;

export const FINISH_COMPANY_RELATION = gql`
  mutation FinishCompanyRelation($id: ID!, $comment: String!) {
    finishCompanyRelation(id: $id, comment: $comment)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: InputForgotPassword) {
    forgotPassword(input: $input)
  }
`;

export const LOGIN = gql`
  mutation Login(
    $type: Int!
    $dni: String
    $dniType: String
    $username: String
    $password: String!
  ) {
    login(
      type: $type
      dni: $dni
      dniType: $dniType
      username: $username
      password: $password
    ) {
      token {
        accessToken
        tokenType
        expiresIn
      }
      user {
        id
        firstName
        lastName
        type
      }
      login @client
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
    logout @client
  }
`;

export const ME = gql`
  query Me {
    me {
      ${userFields}
    }
  }
`;

export const MENU = gql`
  query Menu($id: String!) {
    menu(id: $id) {
      order
      label
      status
      description
      id
      name
      editable
    }
  }
`;

export const MENUS = gql`
  query Menus($term: String) {
    menus(term: $term) {
      label
      description
      status
      id
      editable
    }
  }
`;

export const MY_MENU = gql`
  query MyMenu {
    myMenu {
      id
      name
      label
      description
    }
  }
`;

export const PAGINATE_AGENTS = gql`
  query PaginateAgents(
    $filter: InputFilterAgents
    $pagination: InputPagination
  ) {
    paginateAgents(pagination: $pagination, filter: $filter) {
      total
      items {
        status
        id
        dniType
        dni
        firstName
        lastName
        email
        editable
      }
    }
  }
`;

export const PAGINATE_COMPANY_USERS = gql`
  query PaginateCompanyUsers(
    $filter: InputFilterCompanyUsers
    $pagination: InputPagination
  ) {
    paginateCompanyUsers(pagination: $pagination, filter: $filter) {
      total
      items {
        id
        adviser
        dniType
        dni
        firstName
        lastName
        email
        status
        statusDescription
        user {
          dniType
          dni
          firstName
          lastName
          email
        }
        rol {
          id
          name
        }
        editable
        deletable
      }
    }
  }
`;

export const PAGINATE_TRANSACTIONS = gql`
  query PaginateTransactions(
    $filter: FilterTransactions
    $pagination: InputPagination
  ) {
    paginateTransactions(filter: $filter, pagination: $pagination) {
      total
      items {
        id
        total
        description
        status
        statusDescription
        createdAt
        payments {
          id
          createdAt
          status
        }
        paidInvoice {
          id
          url
        }
      }
    }
  }
`;

export const PAGINATE_USERS = gql`
  query PaginateUsers($filter: InputFilterUsers, $pagination: InputPagination) {
    paginateUsers(filter: $filter, pagination: $pagination) {
      total
      items {
        id
        dniType
        dni
        firstName
        lastName
        email
        editable
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      token {
        accessToken
        tokenType
        expiresIn
      }
      user {
        id
        firstName
        lastName
        type
      }
    }
    refreshToken @client
  }
`;

export const REGISTRY = gql`
  mutation Registry($input: RegistrationInput) {
    registry(input: $input) {
      id
      email
    }
  }
`;

export const REJECT_COMPANY_RELATION = gql`
  mutation RejectCompanyRelation($id: ID!, $comment: String!) {
    rejectCompanyRelation(id: $id, comment: $comment)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: InputResetPassword) {
    resetPassword(input: $input)
  }
`;

export const SEARCH_AGENT = gql`
  query SearchAgent($username: String!) {
    searchAgent(username: $username) {
      firstName
      lastName
      email
      dniType
      dni
      username
    }
  }
`;

export const SEARCH_ADVISERS = gql`
  query SearchAdvisers($input: SearchAdvisersInput!) {
    searchAdvisers(input: $input) {
      id
      dni
      dniType
      firstName
      lastName
      email
    }
  }
`;

export const SEARCH_USERS = gql`
  query SearchUsers($input: SearchUserInput!) {
    searchUsers(input: $input) {
      id
      firstName
      lastName
      email
      dniType
      dni
    }
  }
`;

export const STRONG_PASSWORD = gql`
  query StrongPassword($password: String) {
    strongPassword(password: $password)
  }
`;

export const UPDATE_MENU = gql`
  mutation UpdateMenu($id: ID!, $input: MenuInput) {
    updateMenu(id: $id, input: $input)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: RegistrationInput) {
    updateProfile(input: $input) {
      ${userFields}
    }
  }
`;

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      dniType
      dni
      status
    }
  }
`;

export const VALID_REGISTRATION = gql`
  query ValidRegistration($input: RegistrationInput) {
    validRegistration(input: $input)
  }
`;

export const VALIDATE_PROFILE = gql`
  query ValidateProfile($input: RegistrationInput) {
    validateProfile(input: $input)
  }
`;
