import { gql } from 'apollo-boost';

export const REQUIRED_AUTHORIZATIONS = gql`
  query RequiredAuthorizations($origin: String!) {
    requiredAuthorizations(origin: $origin) {
      id
      description
    }
  }
`;
