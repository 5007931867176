const inputFilter = (data, filter) => {
  let result = {};
  for (let i = 0; i < (filter || []).length; i += 1) {
    result = { ...result, [filter[i]]: data[filter[i]] || undefined };
  }

  return result;
};

export default inputFilter;
