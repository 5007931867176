import { gql } from 'apollo-boost';

export const CAN = gql`
  query Can($input: [InputCan!]!) {
    can(input: $input) {
      permission
      result
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation CreateRole($input: InputRole!) {
    createRole(input: $input) {
      id
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id)
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($id: ID!, $input: InputRole!) {
    updateRole(id: $id, input: $input) {
      id
    }
  }
`;

export const PERMISSIONS_GROUPS = gql`
  query PermissionsGroups {
    permissionsGroups {
      section
      name
      permissions {
        id
        description
        name
      }
    }
  }
`;

export const ROLE = gql`
  query Role($id: ID) {
    role(id: $id) {
      id
      name
      description
      status
      editable
      permissions {
        id
        description
        name
      }
    }
  }
`;

export const PAGINATE_ROLES = gql`
  query PaginateRoles($filter: InputFilterRoles, $pagination: InputPagination) {
    paginateRoles(filter: $filter, pagination: $pagination) {
      total
      items {
        id
        name
        description
        editable
        deletable
      }
    }
  }
`;

export const ROLES = gql`
  query Roles {
    roles {
      id
      name
    }
  }
`;

export const SELECTABLE_AGENT_ROLES = gql`
  query SelectableAgentRoles {
    selectableAgentRoles {
      id
      name
    }
  }
`;

export const SELECTABLE_ROLES = gql`
  query SelectableRoles {
    selectableRoles {
      id
      name
    }
  }
`;
