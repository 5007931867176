import React from 'react';
import * as PropTypes from 'prop-types';
import logo from '../../img/ica-logo.png';
import footer from '../../img/ica-footer.png';

const RawContainer = ({ children }) => (
  <>
    <div className="custom-page-container">
      <img className="custom-raw-logo" src={logo} alt="ICA Logo" />
      {children}
    </div>
    <div className="custom-raw-footer ">
      <img className="custom-footer" src={footer} alt="ICA footer" />
      <p className="footer-text">{process.env.REACT_APP_VERSION}</p>
    </div>
  </>
);

RawContainer.propTypes = {
  children: PropTypes.any
};

export default RawContainer;
