import jwtDecode from 'jwt-decode';

const setAuth = ({ user, token, ...rest }, cache) => {
  const { accessToken } = token;
  const scope = sessionStorage.getItem('scope');

  localStorage.setItem('token', accessToken);
  localStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('scope', scope);

  const { exp } = jwtDecode(accessToken);

  cache.writeData({
    data: {
      checkAuthentication: {
        logged: accessToken !== 'null' && !!accessToken,
        token: accessToken,
        refresh: exp,
        user,
        scope,
        __typename: 'LoginResponse',
      },
    },
  });
  return null;
};

export default setAuth;
