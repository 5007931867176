import { gql } from 'apollo-boost';

export const ADDITIVE_NO_REGISTER = gql`
  query AdditiveNoRegister($filter: FilterAdditiveNoRegister) {
    additiveNoRegister(filter: $filter) {
      id
      name
      country {
        id
        name
      }
    }
  }
`;

export const CATEGORY_NO_REGISTER = gql`
  query CategoryNoRegister {
    categoryNoRegister {
      key
      description
    }
  }
`;

export const CERTIFICATE_DATA_TEXT = gql`
  query CertificateDataTexts($type: String) {
    certificateDataTexts(type: $type) {
      key
      description
    }
  }
`;

export const CERTIFICATE_OPTIONS = gql`
  query CertificateOption {
    certificateOptions {
      key
      description
    }
  }
`;

export const CERTIFICATE_TYPES = gql`
  query CertificateType {
    certificateTypes {
      key
      description
    }
  }
`;

export const COUNTRY_NO_REGISTER = gql`
  query CountryNoRegister {
    countryNoRegister {
      key
      description
    }
  }
`;

export const PAGINATE_CERTIFICATES = gql`
  query Certificates($input: InputCertificate, $pagination: InputPagination) {
    paginateCertificates(input: $input, pagination: $pagination) {
      total
      lastItem
      currentPage
      perPage
      items {
        id
        description
        type
        typeDescription
        created_at
        user {
          firstName
        }
        company {
          name
        }
        file {
          url
        }
      }
    }
  }
`;

export const PRODUCT_TYPES_NO_REGISTER = gql`
  query ProductTypesNoRegister {
    productTypesNoRegister {
      key
      description
    }
  }
`;
