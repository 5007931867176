import React from 'react';
import * as PropTypes from 'prop-types';
import { Result } from 'antd';

const ResultScreen = ({
  status, title, subTitle, children
}) => (
  <Result status={status} title={title} subTitle={subTitle} extra={children} />
);

ResultScreen.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.any,
};

export default ResultScreen;
