import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  Redirect, Route, Switch, withRouter
} from 'react-router-dom';
import Layout from './Layout';
import { menus } from '../../../config/Shared/Menus';
import Routes from './Routes';
import { routes } from '../../../config/Security/Routes';
import { routes as routeConstants } from '../../../config/Shared/Constants';
import { useAuthentication } from '../../../hooks/Security/User';
import { pathTreatment } from '../../../utils/general';

const redirection = (logged) => (logged
  ? routeConstants.URL_HOME
  : `${routeConstants.URL_WITHOUT_LAYOUT}${routeConstants.URL_LOGIN}`);

const foundMenu = (pathname) => {
  const route = routes.find(
    ({ path }) => pathTreatment(path) === pathTreatment(pathname)
  );
  return `${route?.target || ''}`;
};

const LayoutContainer = ({ history, location: { pathname }, handleScope }) => {
  const [collapse, setCollapsed] = useState(false);
  const [redirect, setRedirect] = useState(redirection(false));

  const {
    logged, user, scope, loading
  } = useAuthentication();

  useEffect(() => setRedirect(redirection(logged)), [logged]);

  useEffect(() => {
    handleScope({ companyId: scope?.companyId });
  }, [handleScope, scope]);

  const handleCollapse = () => setCollapsed(!collapse);

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={redirect} />} />
      {!logged && (
        <Route
          path={routeConstants.URL_WITHOUT_LAYOUT}
          render={() => (
            <Routes
              user={user}
              routes={routes.filter(({ layout }) => !layout)}
              redirect
            />
          )}
        />
      )}
      {logged && (
        <Layout
          path={pathname}
          pathname={pathname && foundMenu(pathname)}
          collapsed={collapse}
          handleCollapse={handleCollapse}
          handleScope={handleScope}
          scope={scope}
          menus={menus.primary.filter(
            ({ companyId: company }) => (company && scope?.companyId) || !company
          )}
          headerMenus={menus.header}
          user={user}
          history={history}
        >
          <Routes
            user={user}
            scope={scope}
            routes={routes.filter(
              ({ layout, companyId: company }) => (layout || layout === undefined)
                && ((company && scope?.companyId) || !company)
            )}
            handleScope={handleScope}
          />
        </Layout>
      )}
      {!logged && !loading && (
        <Route render={() => <Redirect to={redirect} />} />
      )}
    </Switch>
  );
};

LayoutContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  handleScope: PropTypes.func,
};

export default withRouter(LayoutContainer);
