import React from 'react';
import * as PropTypes from 'prop-types';
import { Row, Col, Icon } from 'antd';

const Title = ({ title, icon, theme }) => (
  <Row type="flex" className="custom-title-border">
    <Col span={23}>
      <h3 className="custom-title-content">{title}</h3>
    </Col>
    <Col span={1}>
      {icon && <Icon type={icon} theme={theme || 'outlined'} />}
    </Col>
  </Row>
);

Title.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default Title;
