import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Upload } from 'antd';
import Loading from './Loading';

const Files = ({
  files, handleUpload, readonly, title
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleRequest = (request) => {
    setLoading(true);
    setTimeout(() => {
      handleUpload(request);
      setLoading(false);
    }, 0);
  };

  return (
    <Loading loading={loading} custom="custom-component-spin">
      <Upload.Dragger
        accept=".pdf"
        name="files"
        fileList={files}
        multiple
        customRequest={handleRequest}
        disabled={readonly}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">{title || t('company.dragCompany')}</p>
      </Upload.Dragger>
    </Loading>
  );
};

Files.propTypes = {
  readonly: PropTypes.bool,
  files: PropTypes.array,
  handleUpload: PropTypes.func,
  title: PropTypes.string,
};

export default Files;
