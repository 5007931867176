import { gql } from 'apollo-boost';

export const GET_CAPTCHA = gql`
  query GetCaptcha {
    getCaptcha {
      key
      url
    }
  }
`;
