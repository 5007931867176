import { ApolloClient } from 'apollo-client';
import authLink from './authLink';
import errorLink from './errorLink';
import httpLink from './httpLink';
import store from './store';
import { authResolver } from '../../../utils';

const client = (scope) => new ApolloClient({
  link: authLink(scope).concat(errorLink).concat(httpLink),
  cache: store,
  resolvers: authResolver,
});

export default client;
