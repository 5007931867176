import { onError } from 'apollo-link-error';
import { errorHandler } from '../../../utils/errors';

const errorLink = onError((errors) => {
  if (errors?.graphQLErrors?.length > 0) {
    const [error] = errors.graphQLErrors;
    errorHandler({
      errors: errors.graphQLErrors,
      category: error.extensions.category,
    });
  }

  if (errors?.networkError?.statusCode === 503) {
    window.location.replace(errors.networkError?.result?.route);
  }
});

export default errorLink;
