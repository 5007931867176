const blockTypes = (t) => [
  { label: 'H1', style: 'header-two' },
  { label: 'H2', style: 'header-three' },
  { label: 'H3', style: 'header-four' },
  { label: 'H4', style: 'header-five' },
  { label: 'H5', style: 'header-six' },
  { divider: true, label: 'headers' },
  {
    label: t('richText.ul'),
    style: 'unordered-list-item',
    icon: 'unordered-list',
  },
  { label: t('richText.ol'), style: 'ordered-list-item', icon: 'ordered-list' },
  { divider: true, label: 'lists' },
];

export default blockTypes;
