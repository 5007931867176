import { Alert, Icon } from 'antd';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputTooltip from './InputTooltip';

const AlertProductBlocked = ({ block }) => {
  const { t } = useTranslation();

  return (
    <Alert
      message={block.statusDescription}
      description={(
        <>
          {t('product.blockStatusDescription')}
          <span style={{ marginLeft: '10px' }}>
            <InputTooltip
              icon="info-circle"
              text={t('product.blockStatusHelp')}
            />
          </span>
        </>
      )}
      type="error"
      style={{ paddingLeft: '80px' }}
      showIcon
      icon={(
        <Icon
          type="lock"
          theme="twoTone"
          style={{ fontSize: '48px' }}
          twoToneColor="#ff0000"
          className="productBlockBlink"
        />
      )}
    />
  );
};

AlertProductBlocked.propTypes = {
  block: PropTypes.object,
};

export default AlertProductBlocked;
