import { InMemoryCache } from 'apollo-cache-inmemory';

const store = new InMemoryCache();

const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));
const scope = JSON.parse(sessionStorage.getItem('scope'));

store.writeData({
  data: {
    checkAuthentication: {
      logged: token !== 'null' && !!token,
      user,
      scope,
      __typename: 'LoginResponse',
    },
  },
});

export default store;
