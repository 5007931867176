import { gql } from 'apollo-boost';

export const IMPORTABLE = gql`
  query Importable($id: ID!) {
    importable(id: $id) {
      id
      name
      owner
      resolution
      originDescription
      company {
        id
        name
      }
      importers {
        id
        name
      }
      provider {
        id
        name
      }
      producerCompanies {
        id
        name
        producer {
          id
          name
        }
      }
      foreignProviders {
        id
        name
      }
      productType {
        id
        name
      }
      subcategory {
        id
        name
      }
      presentations {
        id
        value
        measureId
        packingTypeId
        packingTypeDescription
        elaborationTypeId
        description
      }
      requireUpdate
      updatableUntil
      outOfDate
    }
  }
`;

export const IMPORTABLES = gql`
  query Importables($resolution: String!) {
    importables(resolution: $resolution) {
      id
      name
      resolution
      productType {
        id
        name
      }
      selectable {
        can
        message
      }
    }
  }
`;

export const IMPORT = gql`
  query Import($id: ID!) {
    import(id: $id) {
      id
      editable
      eliminable
      authorizations {
        id
        fileId
        file {
          id
          extension
          path
          filename
          url
        }
        type
      }
    }
  }
`;

export const REMOVE_IMPORTER = gql`
  mutation RemoveImporter($importId: ID!) {
    removeImporter(importId: $importId)
  }
`;
