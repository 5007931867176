import { routes } from '../../Shared/Constants';

const { URL_WITHOUT_LAYOUT } = routes;

const create = ({ layout, url, ...rest }) => ({
  ...rest,
  layout,
  path: layout ? url : `${URL_WITHOUT_LAYOUT}${url}`,
});

export default create;
