import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createImagePlugin from 'draft-js-image-plugin';
import RichTextToolbar from './RichTextToolbar';
import Loading from './Loading';

const plugins = [createImagePlugin()];

const RichText = forwardRef(({ state, readonly, handleChange }, ref) => {
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (newState) {
      handleChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const handleToolbar = (change) => handleChange(change);

  if (!state) {
    return <Loading loading custom="custom-component-spin" />;
  }

  return (
    <div className="custom-rich-text">
      <RichTextToolbar
        handleToolbar={handleToolbar}
        state={state}
        readonly={readonly}
      />
      <Editor
        ref={ref}
        spellCheck
        readOnly={readonly}
        editorState={state}
        plugins={plugins}
        handleKeyCommand={handleKeyCommand}
        onChange={handleChange}
      />
    </div>
  );
});

RichText.propTypes = {
  readonly: PropTypes.bool,
  state: PropTypes.any,
  handleChange: PropTypes.func,
};

export default RichText;
