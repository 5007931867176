import { gql } from 'apollo-boost';

export const PUBLIC_RESOLUTION = gql`
  query PublicResolution($input: PublicResolutionInput) {
    publicResolution(input: $input) {
      id
      type
      typeDescription
      url
      fileId
      resolution
      createdAt
      updatedAt
      description
    }
  }
`;
