const flow = {
  buttons: {
    amend: 'amend',
    approve: 'approve',
    cancel: 'cancel',
    comment: 'comment',
    extend: 'extend',
    log: 'log',
    pay: 'pay',
    postpone: 'postpone',
    reject: 'reject',
    unlock: 'unblock',
    preview: 'preview',
  },
  owner: {
    owner: 'owner',
    producer: 'producer',
  },
  status: {
    accepted: 'accepted',
    cancelled: 'canceled',
    correctionPendingTech: 'correction-pending-tech',
    correctionRevision1: 'correction-revision-1',
    correctionRevision2: 'correction-revision-2',
    correctionRevision3: 'correction-revision-3',
    companyRevision: 'company-revision',
    draft: 'draft',
    finished: 'finished',
    lockLevel1: 'lock-level-1',
    lockLevel2: 'lock-level-2',
    lockLevel3: 'lock-level-3',
    pending: 'pending',
    pendingAmend: 'pending-amend',
    pendingPayment: 'pending-payment',
    pendingTech: 'pending-tech',
    rejected: 'rejected',
    revision1: 'revision-1',
    revision2: 'revision-2',
    revision3: 'revision-3',
  },
  type: {
    amendExtension: 'amend-extension',
    createExhaustion: 'create-exhaustion',
    extensionExhaustion: 'extension-exhaustion',
    transferOwnership: 'transfer-ownership',
    deleteImporters: 'importer-deletion',
  },
};

export default flow;
