import React from 'react';
import * as PropTypes from 'prop-types';
import { Menu, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

const UserMenu = ({ handleLogout, history, menus }) => {
  const { t } = useTranslation();

  return (
    <Menu>
      {menus?.map(({
        path, index, icon, title
      }) => (
        <Menu.Item key={index} onClick={() => history.push(path)}>
          {icon && <Icon type={icon} />}
          {t(title)}
        </Menu.Item>
      ))}
      <Menu.Item key={-1} onClick={handleLogout}>
        <Icon type="logout" />
        {t('layout.logout')}
      </Menu.Item>
    </Menu>
  );
};

UserMenu.propTypes = {
  history: PropTypes.object,
  menus: PropTypes.array,
  handleLogout: PropTypes.func
};

export default UserMenu;
