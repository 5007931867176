import { gql } from 'apollo-boost';

export const SIGNATURE_REQUEST = gql`
  query signatureRequest($input: InputSignatures) {
    signatureRequest(input: $input)
  }
`;

export const PAGINATION_SIGNATURE_REQUESTS = gql`
  query($pagination: InputPagination) {
    paginateSignatureAll(pagination: $pagination) {
      firstItem
      lastItem
      currentPage
      lastPage
      total
      items {
        id
        code
        created_at
        file {
          url
        }
        type
      }
    }
  }
`;

export const CREATE_SIGNATURE = gql`
  mutation createSignature($input: InputCreateSignature!) {
    createSignature(input: $input)
  }
`;
