import React from 'react';
import * as PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { RichTextReader, ToolbarButton } from '../../../components/Shared';

const VisualizationControls = ({ state }) => {
  const { t } = useTranslation();

  return (
    <div className="richEditor-controls" key="visualization">
      <ToolbarButton
        key="eye"
        icon="eye"
        label={t('richText.visualization')}
        handleToggle={() => Modal.info({
          width: '90%',
          content: <RichTextReader current={state?.getCurrentContent()} />,
          okText: t('common.close'),
        })}
      />
    </div>
  );
};

VisualizationControls.propTypes = {
  state: PropTypes.object,
};

export default VisualizationControls;
