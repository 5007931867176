import { gql } from 'apollo-boost';

export const VALID_ORIGINS = gql`
  query ValidOrigins($companyId: ID) {
    validOrigins(companyId: $companyId) {
      key
      description
    }
  }
`;
