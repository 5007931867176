const formValidations = (t) => ({
  max: {
    number: 10000000000000,
    input: 100,
  },
  min: {
    address: 5,
    number: 0,
    input: 100,
  },
  year: 4,
  rules: {
    type: {
      integer: {
        type: 'integer',
        message: t('rules.integerTypeRequired'),
      },
    },
    max: {
      input: {
        max: 100,
        message: t('rules.maxInput'),
      },
      textArea: {
        max: 1000,
        message: t('rules.maxTextArea'),
      },
      textAreaFlujo: {
        max: 4000,
        message: t('rules.maxTextAreaFlujo'),
      }
    },
    min: {
      telephone: {
        min: 7,
        message: t('rules.minTelephone'),
      },
    },
  },
});

export default formValidations;
