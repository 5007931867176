import { gql } from 'apollo-boost';

export const PACKING_TYPES = gql`
  query PackingTypes {
    packingTypes {
      id
      description
    }
  }
`;
