import {gql} from 'apollo-boost';

export const AMEND_REQUEST = gql`
  mutation AmendRequest($id: ID!, $content: Object!) {
    amendRequest(id: $id, content: $content) {
      id
    }
  }
`;

export const GET_PAY_URL = gql`
  mutation GetPayUrl($transactionId: ID!) {
    getPayUrl(transactionId: $transactionId) {
      message
      url
    }
  }
`;

export const GET_PAYMENT_INFORMATION = gql`
  query GetPaymentInformation($transactionId: ID!) {
    getPaymentInformation(transactionId: $transactionId) {
      transaction {
        id
      }
      ticketId
      reference
      invoiceNumber
      date
      value
      status
      dniType
      ipAddress
    }
  }
`;

export const REQUEST_LOGS = gql`
  query Request($id: ID!) {
    request(id: $id) {
      id
      logs {
        id
        description
        comment
        createdAt
        author {
          firstName
          lastName
        }
      }
    }
  }
`;

export const MY_CONSUMER_COMPANY = gql`
  query ConsumerCompanyTotals(
    $id: ID!
    $input: InputProducerCompanies
    $pagination: InputPagination
  ) {
    company(id: $id) {
      id
      consumerCompaniesTotals {
        group
        total
      }
      paginateConsumerCompanies(input: $input, pagination: $pagination) {
        total
        items {
          id
          status
          statusDescription
          owner
          createdAt
          name
          consumer {
            id
            name
          }
          author {
            id
            firstName
            lastName
          }
          producer {
            id
            name
          }
        }
      }
    }
  }
`;

export const MY_COMPANY_RELATIONS = gql`
  query Me($input: InputCompanyUser, $pagination: InputPagination) {
    me {
      id
      companyRelationsTotals {
        group
        total
      }
      paginateCompanyRelations(input: $input, pagination: $pagination) {
        total
        items {
          id
          status
          statusDescription
          createdAt
          firstName
          lastName
          adviser
          role {
            id
            name
          }
          company {
            id
            name
          }
          author {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const MY_REQUESTS = gql`
  query MyRequests(
    $input: InputRequests
    $pagination: InputPagination
    $type: String
  ) {
    requestTotals(type: $type) {
      group
      total
    }
    paginateRequests(input: $input, pagination: $pagination) {
      total
      items {
        id
        content
        companyId
        type
        description
        status
        statusDescription
        percentage
        createdAt
        typeDescription
        product {
          resolution
        }
        icaProgress {
          color
          time
          expiredAt
        }
        creator {
          firstName
          lastName
        }
        resolution
      }
    }
  }
`;

export const PAGINATE_REQUESTS = gql`
  query PaginateRequests($input: InputRequests, $pagination: InputPagination) {
    paginateRequests(input: $input, pagination: $pagination) {
      total
      items {
        id
        content
        type
        typeDescription
        description
        status
        createdAt
        editable
      }
    }
  }
`;

export const PAGINATE_REQUESTS_NO_CONTENT = gql`
  query PaginateRequests($input: InputRequests, $pagination: InputPagination) {
    paginateRequests(input: $input, pagination: $pagination) {
      total
      items {
        id
        type
        description
        status
        createdAt
        creator {
          id
          firstName
          lastName
        }
        editable
      }
    }
  }
`;

export const REQUEST_DRAFT = gql`
  query RequestDraft($type: String!, $content: Object) {
    requestDraft(type: $type, content: $content)
  }
`;

export const REQUEST = gql`
  query Request($id: ID!) {
    request(id: $id) {
      id
      status
      statusDescription
      content
      comment
      conceptDescription
      companyId
      percentage
      actions
      editable
      type
    }
  }
`;

export const REQUEST_ACTION = gql`
  mutation RequestAction($id: ID!, $action: String!, $args: Object) {
    requestAction(id: $id, action: $action, args: $args) {
      message
      type
      actions {
        type
        action
        args
      }
    }
  }
`;

export const REQUEST_BELONGS_TO_BLOCKED_PRODUCT = gql`
  query RequestBelongsToBlockedProduct($id: ID!) {
    requestBelongsToBlockedProduct(requestId: $id) {
      id
      status
      statusDescription
    }
  }
`;

export const REQUEST_COMMENTS = gql`
  query Request($id: ID!) {
    request(id: $id) {
      id
      status
      content
      concept
      conceptDescription
      comment
      companyId
      comments {
        id
        status
        path
        comment
      }
      actions
      editable
      commentable
      commentables
    }
  }
`;

export const REQUEST_STATES = gql`
  query RequestStates($type: String) {
    requestStates(type: $type) {
      key
      description
    }
  }
`;

export const REQUESTS = gql`
  query Requests($input: InputRequests) {
    requests(input: $input) {
      id
      content
    }
  }
`;

export const SAVE_REQUEST = gql`
  mutation SaveRequest($id: ID, $type: String, $content: Object) {
    saveRequest(id: $id, type: $type, content: $content) {
      id
      content
    }
  }
`;

export const SEND_REQUEST = gql`
  mutation SendRequest($id: ID, $content: Object, $args: Object) {
    sendRequest(id: $id, content: $content, args: $args) {
      message
      type
      actions {
        type
        action
        args
      }
    }
  }
`;

export const TRANSACTION = gql`
  query Transaction($id: ID!) {
    transaction(id: $id) {
      id
      requestId
      total
      status
      details {
        id
        quantity
        serviceDescription
        value
        total
      }
    }
  }
`;
