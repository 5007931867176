import { gql } from 'apollo-boost';

export const CREATE_NUTRIENT = gql`
  mutation CreateNutrient($input: InputNutrient) {
    createNutrient(input: $input) {
      id
    }
  }
`;

export const NUTRIENTS = gql`
  query Nutrients($input: InputSearchNutrients!) {
    nutrients(input: $input) {
      id
      name
      measure {
        description
      }
    }
  }
`;

export const NUTRIENTS_PRODUCT_TYPES = gql`
  query NutrientsProductTypes($productTypeId: ID) {
    nutrientsProductTypes(productTypeId: $productTypeId) {
      id
      nutrient {
        id
        name
      }
    }
  }
`;
