import React from 'react';
import * as PropTypes from 'prop-types';
import { Tooltip, Icon } from 'antd';

const InputTooltip = ({ icon, text, className = '' }) => (
  <Tooltip title={text} position="top">
    <Icon type={icon} className={className} />
  </Tooltip>
);

InputTooltip.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default InputTooltip;
