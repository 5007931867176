import { gql } from 'apollo-boost';

export const COMPANIES_HISTORY = gql`
  query($input: CompaniesHistoryInput) {
    companiesHistory(input: $input) {
      date
      users
      advisers
      products
    }
  }
`;

export const COMPANIES_REQUESTS_HISTORY = gql`
  query($input: CompaniesRequestsHistoryInput) {
    companiesRequestsHistory(input: $input) {
      quantity
      toExpire
      userTime
      icaTime
      totalTime
      lastIcaTime
      userTimeMessage
      icaTimeMessage
      totalTimeMessage
      lastIcaTimeMessage
      requestsStatus {
        accepted
        canceled
        rejected
        draft
        pending
        pendingUser
        pendingIca
      }
      lastIcaRequest
    }
  }
`;

export const COMPANY_REPORTS = gql`
  query {
    companyReports {
      url
      text
    }
  }
`;
