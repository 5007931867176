import React from 'react';
import * as PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import ActionButton from './ActionButton';

const Actions = ({ actions }) => (
  <Row gutter={8} type="flex">
    {actions.map((props, index) => (
      <Col key={index}>
        <ActionButton key={index} {...props} />
      </Col>
    ))}
  </Row>
);

Actions.propTypes = {
  actions: PropTypes.array,
};

export default Actions;
