import { lettersCharacters } from './regularExpresions';
import {
  decimalPrecision,
  maxNumber,
} from '../config/Shared/Constants/general';

export const onlyNumbers = (e) => {
  e.target.value = e.target.value.replace(lettersCharacters, '');
  return e.target.value;
};

export const decimalFormatter = (value) => {
  let newValue = value;

  if (typeof value === 'number') {
    newValue = newValue.toString().split('.');
  } else {
    newValue = newValue.replace(/[^\d.]/g, '');
    newValue = newValue.split('.');
  }

  newValue = newValue[0].substr(0, maxNumber)
    + (newValue.length > 1
      ? `.${newValue[1]}`.substr(0, decimalPrecision + 1)
      : '');
  return newValue;
};

export const setInput = (e, parseFunction, setFunction) => {
  let value;

  if (parseFunction && typeof parseFunction === 'function') {
    value = parseFunction(e);
  }

  if (setFunction && typeof setFunction === 'function') {
    setFunction(value);
  }
};
