import { gql } from 'apollo-boost';

export const ADMIN_CREATE_INGREDIENT = gql`
  mutation CreateIngredient($input: InputIngredient!) {
    createIngredient(input: $input) {
      id
    }
  }
`;

export const ADMIN_CREATE_NUTRIENT = gql`
  mutation CreateNutrient($input: InputNutrient) {
    createNutrient(input: $input) {
      id
    }
  }
`;

export const ADMIN_CREATE_NUTRIENT_RELATION = gql`
  mutation CreateIngredientNutrient($input: InputSaveIngredientNutrient!) {
    createIngredientNutrient(input: $input) {
      id
    }
  }
`;

export const ADMIN_DELETE_INGREDIENT = gql`
  mutation DeleteIngredient($id: ID!) {
    deleteIngredient(id: $id) {
      id
    }
  }
`;

export const ADMIN_DELETE_NUTRIENT = gql`
  mutation DeleteNutrient($id: ID!) {
    deleteNutrient(id: $id) {
      id
    }
  }
`;

export const ADMIN_DELETE_NUTRIENT_RELATION = gql`
  mutation DeleteIngredientNutrient($id: ID!) {
    deleteIngredientNutrient(id: $id) {
      id
    }
  }
`;

export const ADMIN_EDIT_INGREDIENT = gql`
  mutation EditIngredient($id: ID!, $input: InputIngredient!) {
    editIngredient(id: $id, input: $input) {
      id
    }
  }
`;

export const ADMIN_EDIT_NUTRIENT = gql`
  mutation EditNutrient($id: ID!, $input: InputNutrient) {
    editNutrient(id: $id, input: $input) {
      id
    }
  }
`;

export const ADMIN_EDIT_NUTRIENT_RELATION = gql`
  mutation EditIngredientNutrient(
    $id: ID!
    $input: InputSaveIngredientNutrient!
  ) {
    editIngredientNutrient(id: $id, input: $input) {
      id
    }
  }
`;

export const ADMIN_INGREDIENT = gql`
  query Ingredient($id: ID!) {
    ingredient(id: $id) {
      id
      name
      category {
        id
        name
      }
      status
    }
  }
`;

export const ADMIN_INGREDIENTS = gql`
  query PaginateIngredients(
    $input: InputSearchIngredients!
    $pagination: InputPagination
  ) {
    paginateIngredients(input: $input, pagination: $pagination) {
      total
      items {
        id
        name
        category {
          id
          name
        }
        status
        editable
        removable
      }
    }
  }
`;

export const ADMIN_NUTRIENT = gql`
  query Nutrient($id: ID!) {
    nutrient(id: $id) {
      id
      name
      measure {
        id
      }
      status
    }
  }
`;

export const ADMIN_NUTRIENTS = gql`
  query PaginateNutrients(
    $input: InputSearchNutrients!
    $pagination: InputPagination
  ) {
    paginateNutrients(input: $input, pagination: $pagination) {
      total
      items {
        id
        name
        measure {
          id
          description
        }
        status
        editable
        removable
      }
    }
  }
`;

export const ADMIN_NUTRIENTS_RELATION = gql`
  query Ingredient(
    $id: ID!
    $input: InputSearchIngredients
    $pagination: InputPagination
  ) {
    ingredient(id: $id) {
      id
      paginateIngredientsNutrients(input: $input, pagination: $pagination) {
        total
        items {
          id
          nutrient {
            id
            name
            measure {
              id
              description
            }
          }
          quantity
          editable
          removable
        }
      }
    }
  }
`;
