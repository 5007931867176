import React from 'react';
import * as PropTypes from 'prop-types';

const Container = ({ children, className }) => (
  <div className={`custom-component-container ${className}`}>{children}</div>
);

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Container;
