const places = [
  'avenue',
  'avenueStreet',
  'career',
  'circular',
  'cross',
  'encircle',
  'diagonal',
  'kilometer',
  'street',
];

export default places;
