import React from 'react';
import * as PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ExcelFile;
const { ExcelColumn } = ExcelFile;

const resolveValue = (record, key) => {
  if (key.includes('.')) {
    const array = key.split('.');
    let index = 0;
    let response = record;

    while (index < array.length) {
      if (Array.isArray(response[array[index]])) {
        const result = [];

        for (let i = 0; i < response[array[index]].length; i += 1) {
          result.push(resolveValue(response[array[index]][i], array.slice(index + 1, array.length)));
        }

        return result.join(', ');
      }
      response = response[array[index]];
      index += 1;
    }

    return response;
  }

  return record[key];
};

const ExcelExporter = ({ sheets }) => (
  <ExcelFile filename="reporte">
    {(sheets || []).map(({ data, name, columns }) => (
      <ExcelSheet data={data} name={name}>
        {Object.keys(columns || {}).map((key) => (
          <ExcelColumn label={columns[key]} value={(record) => resolveValue(record, key)} />
        ))}
      </ExcelSheet>
    ))}
  </ExcelFile>
);

ExcelExporter.propTypes = {
  sheets: PropTypes.array.isRequired,
};

export default ExcelExporter;
