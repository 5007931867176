import { gql } from 'apollo-boost';

export const CITIES = gql`
  query Cities($stateId: ID) {
    cities(stateId: $stateId) {
      id
      name
    }
  }
`;

export const CITY = gql`
  query City($id: ID!) {
    city(id: $id) {
      id
      name
    }
  }
`;
