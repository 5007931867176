import { gql } from 'apollo-boost';

export const STATES = gql`
  query States {
    states {
      id
      name
    }
  }
`;

export const STATES_CITIES = gql`
  query States {
    states {
      id
      name
      cities {
        id
        name
      }
    }
  }
`;
