import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/react-hooks';
import { EditorState, AtomicBlockUtils } from 'draft-js';
import { Button, Modal, Upload } from 'antd';
import { Loading, ToolbarButton } from '../../../components/Shared';
import { UPLOAD_IMAGE } from '../../../graphql';

const MediaControls = ({ state, handleChange, readonly }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const client = useApolloClient();

  const handleImage = (request) => {
    setLoading(true);
    setTimeout(async () => {
      const { uploadImage } = (
        await client.mutate({
          mutation: UPLOAD_IMAGE,
          variables: { image: request.file },
        })
      ).data;
      const contentState = state.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'image',
        'IMMUTABLE',
        { src: uploadImage }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newState = EditorState.set(state, {
        currentContent: contentStateWithEntity,
      });
      handleChange(
        AtomicBlockUtils.insertAtomicBlock(newState, entityKey, ' ')
      );
      setLoading(false);
      setVisible(false);
    }, 0);
  };

  return (
    <div className="richEditor-controls" key="media">
      <ToolbarButton
        key="image"
        icon="file-image"
        label={t('richText.image')}
        readonly={readonly}
        handleToggle={() => setVisible(true)}
      />
      <Modal
        visible={visible}
        okText={t('common.ok')}
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <Loading loading={loading} custom="custom-component-spin">
          <Upload name="image" accept="image/*" customRequest={handleImage}>
            <Button>{t('common.uploadImage')}</Button>
          </Upload>
        </Loading>
      </Modal>
    </div>
  );
};

MediaControls.propTypes = {
  readonly: PropTypes.bool,
  state: PropTypes.object,
  handleChange: PropTypes.func,
};

export default MediaControls;
