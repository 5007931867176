import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import login from '../../img/ica-login.png';

const ResultTitle = ({ type }) => {
  const { t } = useTranslation();

  switch (type) {
    case 'register':
      return (
        <div className="custom-register-activation">
          <span>
            <Icon type="mail" />
            <p>{t('register.accountActivation')}</p>
          </span>
        </div>
      );
    case 'login':
      return <img src={login} alt="ICA login" className="custom-login-img" />;
    case 'forgotPassword':
    case 'reset':
      return (
        <div className="custom-title-text">
          <span>
            <Icon type="key" />
            <p>{t('forgotPassword.title')}</p>
          </span>
        </div>
      );
    case 'activate':
      return (
        <div className="custom-title-text">
          <span>
            <Icon type="lock" />
            <p>{t('activate.title')}</p>
          </span>
        </div>
      );
    case 'home':
      return (
        <div className="custom-home-info">
          <Icon type="info-circle" theme="filled" />
          <br />
          <h3>{t('common.information')}</h3>
        </div>
      );
    case 'users':
      return (
        <div className="custom-users">
          <Icon type="team" />
          <p>{t('user.title')}</p>
        </div>
      );
    case 'checkout-result':
      return (
        <div className="custom-checkout-title">
          <span>
            <Icon type="gold" />
            <p>{t('checkout.checkoutResult')}</p>
          </span>
        </div>
      );
    case 'searchVerificationCode':
      return (
        <div className="custom-title-text">
          <span>
            <Icon type="file-pdf" />
            <p>{t('searchVerificationCode.title')}</p>
          </span>
        </div>
      );
    case 'searchResolution':
      return (
        <div className="custom-title-text">
          <span>
            <Icon type="file-pdf" />
            <p>{t('searchResolution.title')}</p>
          </span>
        </div>
      );
    default:
      return <div />;
  }
};

ResultTitle.propTypes = {
  type: PropTypes.string,
};

export default ResultTitle;
