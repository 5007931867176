import { gql } from 'apollo-boost';

export const PROFESSIONS = gql`
  query Professions {
    professions {
      key
      description
    }
  }
`;
