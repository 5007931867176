import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const ActionButton = ({
  type,
  readonly,
  loading,
  icon,
  text,
  handleClick,
  confirm,
  confirmText,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip key={`${type}-${icon}`} title={text}>
      {confirm && !readonly ? (
        <div onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            placement="bottomRight"
            trigger="click"
            onConfirm={handleClick}
            title={confirmText}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <Button
              className={`custom-action-button ${className || ''}`}
              key={`${type}-${icon}`}
              type={type}
              shape="circle"
              icon={icon}
              loading={loading || false}
            />
          </Popconfirm>
        </div>
      ) : (
        <Button
          className={`custom-action-button ${className || ''}`}
          key={`${type}-${icon}`}
          type={type}
          onClick={handleClick}
          shape="circle"
          icon={icon}
          disabled={readonly}
          loading={loading || false}
        />
      )}
    </Tooltip>
  );
};

ActionButton.propTypes = {
  confirm: PropTypes.bool,
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
  confirmText: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  handleClick: PropTypes.func,
};

export default ActionButton;
