import { gql } from 'apollo-boost';

export const RECORDS = gql`
  query Records {
    records {
      id
      content
    }
  }
`;
export const PAGINATION_RESOLUTIONS = gql`
  query($input: InputResolutions, $pagination: InputPagination) {
    paginateResolutions(input: $input, pagination: $pagination) {
      firstItem
      lastItem
      currentPage
      lastPage
      total
      items {
        id
        type
        typeDescription
        url
        resolution
        createdAt
        description
      }
    }
  }
`;
