import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { capitalize } from '../../utils/Shared';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const BarCharter = ({ data, label }) => {
  const colors = data.reduce((result) => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);

    return result.concat([{ r, g, b }]);
  }, []);

  const list = {
    labels: data.map(({ date }) => capitalize(moment(date).format('MMMM/YYYY'))),
    datasets: [
      {
        label,
        data: data.map(({ value }) => value),
        backgroundColor: colors.map(({ r, g, b }) => `rgba(${r}, ${g}, ${b}, 0.2)`),
        borderColor: colors.map(({ r, g, b }) => `rgba(${r}, ${g}, ${b}, 1)`),
        borderWidth: 1,
      }
    ]
  };

  return (
    <Bar data={list} options={options} />
  );
};

BarCharter.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
};

export default BarCharter;
