import jwtDecode from 'jwt-decode';
import setAuth from './setAuth';
import { CHECK_AUTHENTICATION } from '../graphql';
import { logout } from './authentication';

const authResolver = {
  Query: {
    checkAuthentication({ checkLogin }, args, { cache }) {
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));
      const scope = JSON.parse(sessionStorage.getItem('scope'));

      if (checkLogin === false) {
        return logout(cache);
      }

      const { exp } = jwtDecode(token);

      return {
        logged: token !== 'null' && !!token,
        user,
        token,
        refresh: exp,
        scope: {
          companyId: null,
          ...scope,
          __typename: 'Scope',
        },
        __typename: 'LoginResponse',
      };
    },
    checkRefresh(root, { token, refreshing, current }) {
      const savedToken = localStorage.getItem('token');
      const value = localStorage.getItem('refreshing');

      const savedRefreshing = value !== null && value !== 'null' ? parseInt(value) : -1;

      if (token && token !== savedToken) {
        return false;
      }

      if (
        typeof refreshing === 'number'
        && (refreshing === savedRefreshing
          || (refreshing !== savedRefreshing && current === savedRefreshing))
      ) {
        return null;
      }

      if (typeof refreshing === 'number' && refreshing !== savedRefreshing) {
        return savedRefreshing;
      }

      return savedRefreshing + 1;
    },
  },
  Mutation: {
    beforeRefresh(root, { refreshing }) {
      localStorage.setItem('refreshing', refreshing.toString());
      return refreshing;
    },
    login({ login }, args, { cache }) {
      if (login) {
        setAuth(login, cache);
      }
    },
    logout(root, args, { cache }) {
      logout(cache);
      return null;
    },
    refreshToken({ refreshToken }, args, { cache }) {
      if (refreshToken) {
        setAuth(refreshToken, cache);
        localStorage.setItem('refreshing', null);
      }
    },
    updateScope(root, { scope: { companyId, ...rest } }, { cache }) {
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));
      const scope = { ...rest, companyId, __typename: 'Scope' };
      sessionStorage.setItem('scope', JSON.stringify(scope));

      const { exp } = jwtDecode(token);

      const data = {
        checkAuthentication: {
          logged: token !== 'null' && !!token,
          token,
          refresh: exp,
          user,
          scope,
          __typename: 'LoginResponse',
        },
      };

      cache.writeData({ data });
      cache.writeQuery({
        query: CHECK_AUTHENTICATION,
        data: { checkAuthentication: { ...data.checkAuthentication, scope } },
      });
    },
  },
};

export default authResolver;
