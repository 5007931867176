import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Icon, Steps, Row, Col, Button
} from 'antd';
import ActionButton from './ActionButton';

const Stepper = ({
  titleInfo,
  steps,
  step,
  handleChange,
  children,
  left,
  right,
  handleLeft,
  handleRight,
  loading,
  actions,
}) => (
  <Row type="flex">
    <Col span={4}>
      <Steps
        current={step}
        onChange={handleChange}
        direction="vertical"
        type="navigation"
        className="custom-stepper-steps"
      >
        {steps.map(({ title, icon, fill }, index) => (
          <Steps.Step
            className="custom-stepper-step"
            key={index}
            description={`${index + 1}. ${title}`}
            icon={(
              <Icon
                type={icon}
                theme={fill ? 'filled' : 'outlined'}
                className="custom-stepper-icon"
              />
            )}
          />
        ))}
      </Steps>
    </Col>
    <Col span={20}>
      <Row type="flex" className="custom-stepper-header" justify="start">
        <Col span={12}>
          <h3 className="custom-stepper-title">{titleInfo}</h3>
        </Col>
        <Col span={12}>
          <Row
            className="custom-form-vertical-margin"
            type="flex"
            justify="end"
          >
            {actions?.map((props, index) => (
              <Col span={2} key={index}>
                <ActionButton
                  {...props}
                  loading={loading}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>{children}</Col>
      </Row>
      <Row
        type="flex"
        className="custom-buttons-container"
        gutter={8}
        justify="center"
      >
        <Col span={4}>
          {left && (
            <Button onClick={handleLeft} className="custom-full-width">
              <Icon type="arrow-left" />
              <span>{left}</span>
            </Button>
          )}
        </Col>
        <Col span={4}>
          {right && (
            <Button
              onClick={handleRight}
              type="primary"
              className="custom-full-width"
              loading={loading || false}
            >
              <span>{right}</span>
              <Icon type="arrow-right" />
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  </Row>
);

Stepper.propTypes = {
  loading: PropTypes.bool,
  left: PropTypes.string,
  right: PropTypes.string,
  titleInfo: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  actions: PropTypes.array,
  steps: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
  handleChange: PropTypes.func,
  handleLeft: PropTypes.func,
  handleRight: PropTypes.func,
};

export default Stepper;
