import { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import {
  PAGINATE_AGENTS,
  PAGINATE_COMPANY_USERS,
  PAGINATE_USERS,
} from '../../../graphql';

const useUsers = ({ type, pagination, filter }) => {
  const [data, setData] = useState(undefined);
  const [refetch, setRefetch] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const update = () => setRefetch(new Date());

  useEffect(() => {
    switch (type) {
      case 'relation':
        setLoading(true);
        client
          .query({
            query: PAGINATE_COMPANY_USERS,
            variables: {
              filter,
              pagination: {
                perPage: pagination.pageSize,
                page: pagination.current,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then(({ data: request }) => {
            setData(request);
            setLoading(false);
          })
          .catch((err) => setLoading(false));
        break;
      case 'users':
        setLoading(true);
        client
          .query({
            query: PAGINATE_USERS,
            variables: {
              filter,
              pagination: {
                perPage: pagination.pageSize,
                page: pagination.current,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then(({ data: request }) => {
            setData(request);
            setLoading(false);
          })
          .catch((err) => setLoading(false));
        break;
      case 'agents':
        setLoading(true);
        client
          .query({
            query: PAGINATE_AGENTS,
            variables: {
              filter,
              pagination: {
                perPage: pagination.pageSize,
                page: pagination.current,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then(({ data: request }) => {
            setData(request);
            setLoading(false);
          })
          .catch((err) => setLoading(false));
        break;
      default:
        break;
    }
  }, [client, type, pagination, filter, refetch]);

  return { data, loading, refetch: update };
};

useUsers.propTypes = {
  type: PropTypes.string,
  pagination: PropTypes.object,
};

export default useUsers;
