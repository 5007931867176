import React from 'react';
import * as PropTypes from 'prop-types';
import { Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Title from './Title';

const Old = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Row type="flex">
      <Col span={24}>
        <Title title={t('company.oldDescription')} />
      </Col>
      <Col span={24}>
        <Divider className="custom-divider" />
      </Col>
      <Col span={24}>{children}</Col>
    </Row>
  );
};

Old.propTypes = {
  children: PropTypes.object,
};

export default Old;
