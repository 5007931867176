import { gql } from 'apollo-boost';

export const CREATE_INGREDIENT = gql`
  mutation CreateIngredient($input: InputIngredient!) {
    createIngredient(input: $input) {
      id
      name
      description
      category {
        id
        name
      }
    }
  }
`;

export const CREATE_INGREDIENTS_NUTRIENTS = gql`
  mutation CreateIngredientsNutrients($input: InputIngredientNutrient!) {
    createIngredientsNutrients(input: $input) {
      id
    }
  }
`;

export const INGREDIENTS = gql`
  query Ingredients($input: InputSearchIngredients!) {
    ingredients(input: $input) {
      id
      name
      description
      category {
        id
        name
      }
    }
  }
`;

export const INGREDIENTS_NUTRIENTS = gql`
  query Ingredients($input: InputSearchIngredients!) {
    ingredients(input: $input) {
      id
      ingredientNutrients {
        id
        nutrient {
          id
          name
        }
      }
    }
  }
`;

export const INGREDIENT_NUTRIENTS_LIST = gql`
  query ing($id: ID!) {
    ingredient(id: $id) {
      ingredientNutrients {
        quantity
        nutrient {
          name
          measure {
            description
          }
        }
      }
    }
  }
`;
