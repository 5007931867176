import { gql } from 'apollo-boost';

export const UPLOAD = gql`
  mutation Upload($upload: Upload) {
    upload(upload: $upload) {
      id
      filename
      extension
      path
      url
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload) {
    uploadImage(image: $image)
  }
`;

export const DOC_TYPES = gql`
  query DocTypes($type: String!) {
    docTypes(type: $type) {
      description
      key
    }
  }
`;
