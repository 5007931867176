import { gql } from 'apollo-boost';

export const PUBLIC_STATS = gql`
  query($input: PublicStatsInput) {
    publicStats(input: $input) {
      advisers
      companies {
        total
        values {
          date
          value
        }
      }
      products {
        total
        values {
          date
          value
        }
      }
      icaTimes {
        name
        value
      }
      requests {
        type
        status
        total
        manuals
        automatics
      }
    }
  }
`;
