import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table as AntTable } from 'antd';

const Table = ({
  data,
  titles,
  renders,
  actions,
  pagination,
  rowKey,
  footer,
  rowSelection,
  handleTable = () => {},
}) => {
  const { t } = useTranslation();

  let columns = Object.keys(titles).map((x) => ({
    dataIndex: x,
    key: x,
    title: t(titles[x]),
  }));

  if (renders?.length > 0) {
    for (const render of renders) {
      const record = columns.find(({ key }) => render.key === key);
      const index = columns.indexOf(record);

      columns[index] = {
        ...record,
        ...render,
      };
    }
  }

  if (actions) {
    columns = columns.concat([
      {
        title: t('common.actions'),
        dataIndex: '',
        key: 'actions',
        width: 140,
        ...((renders || []).find(({ key }) => key === 'actions') || {}),
        render: (text, record) => (
          <div className="custom-table-buttons">
            <Row gutter={8} type="flex">
              {actions.map((x, index) => (
                <Col key={index}>
                  {x({
                    text,
                    record,
                  })}
                </Col>
              ))}
            </Row>
          </div>
        ),
      },
    ]);
  }

  return (
    <AntTable
      rowSelection={rowSelection}
      className="custom-table"
      dataSource={data}
      columns={columns}
      rowKey={rowKey}
      footer={footer}
      pagination={{ ...pagination, showSizeChanger: true }}
      onChange={handleTable}
    />
  );
};

Table.propTypes = {
  rowKey: PropTypes.string.isRequired,
  titles: PropTypes.object.isRequired,
  pagination: PropTypes.object,
  rowSelection: PropTypes.object,
  actions: PropTypes.array,
  data: PropTypes.array,
  renders: PropTypes.array,
  footer: PropTypes.func,
  handleTable: PropTypes.func,
};

export default Table;
