import React from 'react';
import * as PropTypes from 'prop-types';
import { Alert } from 'antd';

const UpdateAlert = ({
  expired, title, message, date
}) => (
  <Alert
    message={title}
    description={message.replace('$[{date}]', date)}
    type={expired ? 'error' : 'info'}
    showIcon
  />
);

UpdateAlert.propTypes = {
  expired: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
};

export default UpdateAlert;
