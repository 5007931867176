import { gql } from 'apollo-boost';

const generals = `
  id
  company {
    id
    name
  }
  old
  oldDocuments {
    id
    issuedAt
    registration
    file {
      id
      extension
      path
      filename
      url
    }
  }
  name
  productTypeId
  subcategoryId
  origin
  providerId
  owner
  imports {
    id
    company {
      id
      name
    }
  }
  producerCompaniesIds
  producerCompanies {
    id
    name
    dni
    dniType
    producer {
      id
      name
      dni
      dniType
    }
  }
  plantsIds
  foreignProviders {
    id
  }
  currentResolution {
    fileId
    url
    resolution
    createdAt
    description
  }
`;

const formula = `
  formulaMeasureId
  formulaTotal
  formula {
    id
    quantity
    measureId
    ingredientId
    ingredient {
      id
      name
      category {
        id
        name
      }
    }
    groupId
  }
  groups {
    id
    name
    description
  }
`;

const composition = `
  compositionMeasureId
  compositionTotal
  composition {
    id
    quantity
    limit
    status
    nutrientId
    nutrient {
      id
      name
    }
    measureId
  }
`;

const elaboration = `
  elaboration
  techniques
  presentations {
    id
    value
    measureId
    packingTypeId
    elaborationTypeId
    description
    packingTypeDescription
  }
`;

const indications = `
  indications
  indicationsSpecies {
    id
    specieId
    subSpecieId
    stagesIds
  }
  usefulLife
  recommendedAmount
`;

const authorizations = `
  authorizations {
    id
    type
    file {
      id
      extension
      path
      filename
      url
    }
  }
`;

const security = `
  activatable
  blockable
  cancellable
  editable
  requireUpdate
  status
  statusDescription
  unblockable
  updatableUntil
  outOfDate
`;

export const ACTIVATE_PRODUCT = gql`
  mutation ActivateProduct($id: ID!) {
    activateProduct(id: $id)
  }
`;

export const BLOCK_PRODUCT = gql`
  mutation BlockProduct($id: ID!, $input: BlockProductInput) {
    blockProduct(id: $id, input: $input) {
      id
    }
  }
`;

export const CANCEL_PRODUCT = gql`
  mutation CancelProduct($id: ID!, $input: InputCancelProduct!) {
    cancelProduct(id: $id, input: $input)
  }
`;

export const COMPANY_PRODUCERS = gql`
  query Company($id: ID!, $input: InputProducerCompanies) {
    company(id: $id) {
      id
      producerCompanies(input: $input) {
        id
        name
        dni
        dniType
        producer {
          id
          name
          dni
          dniType
        }
      }
    }
  }
`;

export const COMPANY_PRODUCT_TYPES = gql`
  query Company($id: ID!, $input: InputProductTypes) {
    company(id: $id) {
      id
      productTypes(input: $input) {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`;

export const COMPANY_SEARCH_PLANTS = gql`
  query Company($id: ID!, $input: InputPlants) {
    company(id: $id) {
      id
      plants(input: $input) {
        id
        name
      }
    }
  }
`;

export const COMPANY_SEARCH_PROVIDERS = gql`
  query Company($id: ID!, $input: InputProviders) {
    company(id: $id) {
      id
      providers(input: $input) {
        id
        name
      }
    }
  }
`;

export const GROUPS = gql`
  query Product($id: ID) {
    product(id: $id) {
      id
      name
      groups {
        id
        name
      }
    }
  }
`;

export const PAGINATE_IMPORTS = gql`
  query PaginateImports($pagination: InputPagination) {
    paginateImports(pagination: $pagination) {
      total
      items {
        id
        product {
          id
          name
          productType {
            id
            name
          }
        }
      }
    }
  }
`;

export const PAGINATE_PRODUCTS = gql`
  query PaginateProducts($input: InputProducts, $pagination: InputPagination) {
    paginateProducts(input: $input, pagination: $pagination) {
      total
      items {
        id
        name
        productType {
          id
          name
        }
        status
        statusDescription
        activatableUntil
        selectable {
          can
          message
        }
      }
    }
  }
`;

export const PRODUCT = gql`
  query Product($id: ID) {
    product(id: $id) {
      ${generals}
      ${formula}
      ${composition}
      ${elaboration}
      ${indications}
      ${authorizations}
      ${security}
    }
  }
`;

export const PRODUCT_EXHAUST = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      exhaustion {
        type
      }
    }
  }
`;

export const PRODUCT_TYPES = gql`
  query ProductTypes($parentId: ID!) {
    productTypes(parentId: $parentId) {
      id
      name
      children {
        id
        name
      }
    }
  }
`;

export const PRODUCTS = gql`
  query Products {
    products {
      id
      name
    }
  }
`;

export const GET_PRODUCTS = gql`
  query Product($input: InputProducts) {
    products(input: $input) {
      id
      name
    }
  }
`;

export const FOREIGN_PRODUCTS = gql`
  query ForeignProducts($resolution: String!) {
    foreignProducts(resolution: $resolution) {
      id
      name
      resolution
      status
      origin
      owner
      productType {
        id
        name
      }
      selectable {
        can
        message
      }
    }
  }
`;

export const FOREIGN_PRODUCT = gql`
  query ForeignProduct($id: ID!) {
    foreignProduct(id: $id) {
      id
      name
      resolution
      originDescription
      status
      origin
      owner
      company {
        name
      }
      provider {
        name
      }
      subcategory {
        name
      }
      selectable {
        can
        message
      }
    }
  }
`;

export const UNBLOCK_PRODUCT = gql`
  mutation UnblockProduct($id: ID!, $input: UnblockProductInput) {
    unblockProduct(id: $id, input: $input) {
      id
    }
  }
`;
