import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'antd';

const ToolbarButton = ({
  active, label, icon, handleToggle, apply, readonly
}) => {
  const onToggle = (e) => {
    e.preventDefault();
    handleToggle(apply);
  };

  return (
    <Button disabled={readonly} type={active ? 'primary' : 'ghost'} className="RichEditor-styleButton" onMouseDown={onToggle} icon={icon} title={label}>
      {!icon && label}
    </Button>
  );
};

ToolbarButton.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  apply: PropTypes.any,
  handleToggle: PropTypes.func
};

export default ToolbarButton;
