import { Modal } from 'antd';
import { flow } from '../config/Shared/Constants';

export const resolveButtons = ({
  buttons,
  status,
  handleActions,
  handleLog,
  history,
  t,
}) => {
  const handleCheckout = () => handleActions(undefined, flow.buttons.pay);

  const handleCancel = () => Modal.confirm({
    okText: t('common.yes'),
    cancelText: t('common.no'),
    content: t('request.confirmCancel'),
    onOk: () => handleActions(undefined, flow.buttons.cancel),
  });

  const actions = [];

  if (buttons.find((x) => x === flow.buttons.log)) {
    actions.push({
      key: 'log',
      handleClick: handleLog,
      icon: 'audit',
      text: t('log.log'),
      type: 'ghost',
    });
  }

  if (
    buttons.find((x) => x === flow.buttons.pay)
    && status === flow.status.pendingPayment
  ) {
    actions.push({
      key: 'gold',
      handleClick: handleCheckout,
      icon: 'gold',
      text: t('checkout.checkoutButton'),
      type: 'primary',
    });
  }

  if (buttons.find((x) => x === flow.buttons.cancel)) {
    actions.push({
      key: 'delete',
      handleClick: handleCancel,
      icon: 'delete',
      text: t('request.cancelButton'),
      type: 'danger',
    });
  }

  actions.push({
    key: 'close',
    handleClick: history.goBack,
    icon: 'close',
    text: t('company.return'),
    type: 'ghost',
  });

  return actions;
};
