import React from 'react';
import * as PropTypes from 'prop-types';
import { RichUtils } from 'draft-js';
import {
  BlockStyleControls,
  InlineStyleControls,
  MediaControls,
  VisualizationControls,
} from '../../config/Shared/RichText';

const RichTextToolbar = ({ state, handleToolbar, readonly }) => {
  const handleBlockToggle = (style) => !readonly && handleToolbar(RichUtils.toggleBlockType(state, style));
  const handleInlineToggle = (style) => !readonly && handleToolbar(RichUtils.toggleInlineStyle(state, style));

  return (
    <div className="custom-rich-text-toolbar">
      <InlineStyleControls
        state={state}
        handleToggle={handleInlineToggle}
        readonly={readonly}
      />
      <BlockStyleControls
        state={state}
        handleToggle={handleBlockToggle}
        readonly={readonly}
      />
      <MediaControls
        state={state}
        handleChange={handleToolbar}
        readonly={readonly}
      />
      <VisualizationControls state={state} />
    </div>
  );
};

RichTextToolbar.propTypes = {
  readonly: PropTypes.bool,
  state: PropTypes.object,
  handleToolbar: PropTypes.func,
};

export default RichTextToolbar;
