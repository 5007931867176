import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const Friends = ({ text, handleOk, handleCancel }) => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{text}</p>
      <Button type="primary" onClick={handleOk}>
        {t('common.ok')}
      </Button>
      <Button type="ghost" onClick={handleCancel}>
        {t('common.refuse')}
      </Button>
    </div>
  );
};

Friends.propTypes = {
  text: PropTypes.string,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func
};

export default Friends;
