import { setContext } from 'apollo-link-context';

const authLink = ({ companyId }) => setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  if (companyId) {
    return {
      headers: {
        ...headers,
        'X-Company-Id': companyId,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default authLink;
