import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';

const Check = ({
  handleSelect, review, path, span
}) => {
  const { status } = (review && review[path]) || {};

  const buttonProps = status === undefined
    ? { className: 'custom-warning-background', icon: 'exclamation' }
    : status
      ? { type: 'primary', icon: 'check' }
      : { type: 'danger', icon: 'close' };

  return (
    <Row type="flex" justify="end" gutter={8}>
      <Col span={span || 1}>
        <Button
          onClick={() => handleSelect({ ...review[path], path })}
          shape="circle"
          {...buttonProps}
        />
      </Col>
    </Row>
  );
};

Check.propTypes = {
  path: PropTypes.string,
  span: PropTypes.number,
  review: PropTypes.object,
  handleSelect: PropTypes.func,
};

export default Check;
