import React, { forwardRef } from 'react';
import { Input } from 'antd';
import * as PropTypes from 'prop-types';
import {
  numberWithExponential,
  regExpInputNumber,
} from '../../utils/regularExpresions';

const InputNumber = forwardRef(({ ...props }, ref) => {
  const overrideProps = {
    ...props,
    ref,
    style: { textAlign: 'right' },
    type: 'string',
    onBlur: (e) => {
      let { value } = e.target;
      if (!numberWithExponential.test(value)) {
        const regex = /[^\d]$/;
        while (value.length > 0 && regex.test(value)) {
          value = value.replace(regex, '');
        }
      }
      if (value.indexOf('.') >= 0) {
        const parts = value.split('.');
        if (value.indexOf('e') >= 0) {
          const [decimal, exponential] = parts[1].split('e');
          parts[1] = decimal;
          parts[2] = exponential;
        } else {
          parts[2] = '';
        }
        parts[1] = parts[1].replace(/0+$/, '');
        value = `${parts[0]}${parts[1].length ? `.${parts[1]}` : ''}${
          parts[2].length ? `e${parts[2]}` : ''
        }`;
      }
      props.onChange(value);
    },

    onChange: (e) => {
      let { value } = e.target;
      value = value
        .replace(/(^0+$)|(^0e$)/, '0')
        .replace(/^0+([1-9])/, '$1')
        .replace(/^0+\./, '0.')
        .replace(/(e-?)((2[1-9])|[3-9]\d)/, '$120');
      if (regExpInputNumber.test(value) || value === '') {
        props.onChange(value);
      }
    },
  };

  return <Input {...overrideProps} />;
});

InputNumber.propTypes = {
  precision: PropTypes.number,
};

export default InputNumber;
