import { EditorState } from 'draft-js';
import { STRONG_PASSWORD } from '../graphql';
import { errorMessage } from './errors';

export const formValidation = (form, keys) => form.validateFields(keys);

export const matchPassword = async (
  { password, value },
  { client },
  t,
  callback
) => {
  if (password !== value) {
    callback(t('rules.passwordConfirmation'));
  } else {
    try {
      const { strongPassword } = (
        await client.query({
          query: STRONG_PASSWORD,
          variables: { password: value },
        })
      ).data;
      if (strongPassword) {
        callback();
      } else {
        callback(t('rules.strongPassword'));
      }
    } catch (e) {
      const { message } = errorMessage(e);
      callback(message);
    }
  }
};

export const handleAdviserNumber = async (
  { professionKey, isTech, professionId },
  { client, query },
  callback
) => {
  try {
    await client.query({
      query,
      variables: { input: { professionKey, professionId, isTech } },
    });
    callback();
  } catch (e) {
    const { message } = errorMessage(e);
    callback(message);
  }
};

export const handleAdviserProfession = (form, callback) => {
  callback();
  const { professionKey } = form.getFieldsError(['professionKey']);
  if (professionKey) {
    formValidation(form, ['professionKey']);
  }
};

export const documentValidation = ({ dniType, dni, dniConfirmation }, t) => {
  const message = [];
  if (dniType !== undefined && dniType.trim() === '') {
    message.push(t('rules.documentTypeRequired'));
  }

  if (dni !== undefined && dni.trim() === '') {
    message.push(t('rules.documentRequired'));
  }

  if (dni !== dniConfirmation) {
    message.push(t('rules.documentConfirmation'));
  }

  return message;
};

export const locationValidation = ({
  state, cityId, address
}, t) => {
  const message = [];

  if (state !== undefined && state === '') {
    message.push(t('rules.provinceRequired'));
  }

  if (cityId !== undefined && cityId === '') {
    message.push(t('rules.cityRequired'));
  }

  if (address !== undefined && address === '') {
    message.push(t('rules.addressRequired'));
  }

  return message;
};

export const emailValidation = ({ email, emailConfirmation }, t) => {
  const message = [];
  if (email !== undefined && email.trim() === '') {
    message.push(t('rules.emailRequired'));
  }

  if (email !== emailConfirmation) {
    message.push(t('rules.emailConfirmation'));
  }

  return message;
};

export const oldDocumentsValidation = ({ old, oldDocuments }, t) => {
  const message = [];
  if (old) {
    if (oldDocuments !== undefined && oldDocuments.length === 0) {
      message.push(t('rules.oldDocumentsRequired'));
    }
  }

  return message;
};

export const richTextValidation = ({ value, error }) => {
  const message = [];

  if (
    value !== undefined
    && value?.getCurrentContent().getPlainText()
      === EditorState.createEmpty().getCurrentContent().getPlainText()
  ) {
    message.push(error);
  }

  return message;
};
