import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  Avatar, Badge, Button, Col, Dropdown, Popover, Row
} from 'antd';
import moment from 'moment';
import UserMenu from './UserMenu';
import { Loading } from '../../Shared';
import {
  BEFORE_REFRESH, CHECK_LOGIN, CHECK_REFRESH, REFRESH_TOKEN,
} from '../../../graphql';
import FriendsRequest from './FriendsRequest';

const HeaderUser = ({ user, handleLogout, ...rest }) => {
  const client = useApolloClient();
  const { loading, data, refetch } = useQuery(CHECK_LOGIN, {
    fetchPolicy: 'network-only',
    pollInterval: 5 * 60 * 1000,
  });
  const { companyRelations } = data?.me?.pendingsCount || {};

  const { checkAuthentication, unix } = data || {};

  useEffect(() => {
    const timeout = (end = 0, now = 0) => {
      const endDate = moment(end * 1000);
      const actualDate = moment(now * 1000);
      const remaining = endDate.diff(actualDate, 'milliseconds');
      const timeBefore = 5 * 60 * 1000;
      return remaining >= timeBefore ? remaining - timeBefore : 1000;
    };

    const validateRefresh = async (refreshing, current) => {
      try {
        const token = checkAuthentication?.token;
        const { data: checkData } = await client.query({
          query: CHECK_REFRESH,
          variables: { token, refreshing, current },
        });

        const { checkRefresh: check } = checkData || {};

        if (typeof check === 'number' && typeof refreshing === 'number') {
          setTimeout(() => {
            validateRefresh(refreshing, check);
          }, 30 * 1000);
        }

        if (typeof check === 'number' && refreshing === undefined) {
          setTimeout(() => {
            validateRefresh(check);
          }, 30 * 1000);
        }

        if (check === null) {
          await client.mutate({
            mutation: BEFORE_REFRESH,
            variables: { refreshing },
          });
          await client.mutate({
            mutation: REFRESH_TOKEN,
          });
        }
      } catch (e) {
        console.log('refresh token catch', e);
      }
    };

    const timer = setTimeout(
      validateRefresh,
      timeout(checkAuthentication?.refresh, unix)
    );

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [checkAuthentication, unix]);

  return (
    <Loading loading={loading} custom="custom-component-spin">
      <Row type="flex" gutter={8} justify="start">
        <Col span={6}>
          <Popover
            destroyTooltipOnHide
            overlayStyle={{ width: 370 }}
            content={(
              <FriendsRequest
                counter={companyRelations}
                updateCounter={refetch}
              />
            )}
            placement="bottom"
            trigger="click"
          >
            <Badge count={companyRelations} overflowCount={20}>
              <Button icon="usergroup-add" className="custom-friends-icon" />
            </Badge>
          </Popover>
        </Col>
        <Col span={18}>
          <Dropdown
            overlay={() => <UserMenu handleLogout={handleLogout} {...rest} />}
            placement="bottomRight"
          >
            <div className="custom-header-user">
              <Avatar icon="user" />
              <span>{user?.firstName}</span>
            </div>
          </Dropdown>
        </Col>
      </Row>
    </Loading>
  );
};

HeaderUser.propTypes = {
  user: PropTypes.object,
  handleLogout: PropTypes.func,
};

export default HeaderUser;
