import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/react-hooks';
import { Searcher } from '../../Shared';
import { COMPANIES, COMPANY_SCOPE } from '../../../graphql';
import { isNullOrEmpty, notify } from '../../../utils';
import { errorEntityNotFound } from '../../../utils/errors';

const CompanySelector = ({
  pathname, history, scope, handleScope
}) => {
  const { companyId } = scope || {};

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [preData, setPreData] = useState(undefined);

  const client = useApolloClient();

  useEffect(() => {
    if (!isNullOrEmpty(companyId)) {
      client
        .query({ query: COMPANY_SCOPE, variables: { id: companyId } })
        .then(({ data: scopeData }) => {
          const { selectable } = scopeData?.company || {};

          if (selectable?.can) {
            setPreData(scopeData);
          } else {
            const redirect = pathname.split('/');
            redirect[2] = '_';

            notify({ type: 'warning', message: selectable?.message });
            history.push(redirect.join('/'));
          }
        })
        .catch((error) => errorEntityNotFound(
          error,
          history,
          `/home/_`,
          companyId !== undefined && companyId !== '_'
        ));
    }
    // eslint-disable-next-line
  }, [client, companyId]);

  const { t } = useTranslation();

  const handleSearch = async (value) => {
    setLoading(true);
    setData(
      (
        await client.query({
          query: COMPANIES,
          variables: { input: { term: value } },
          fetchPolicy: 'network-only',
        })
      )?.data?.companies
    );
    setLoading(false);
  };

  const resolveData = data?.map(({ id, name }) => ({ key: id, label: name }))
    || (companyId
      && preData && [{ key: preData.company.id, label: preData.company.name }])
    || [];

  const resolveValue = data
      ?.filter(({ id }) => id === companyId)
      .map(({ id, name }) => ({ key: id, label: name }))
    || (companyId
      && preData && [{ key: preData.company.id, label: preData.company.name }])
    || [];

  const handleCompany = (value) => {
    const redirect = pathname.split('/');
    redirect[2] = '_';

    if (value) {
      const { key } = value;
      const { selectable } = data?.find(({ id }) => id === key) || {};

      if (selectable?.can) {
        redirect[2] = key;
        handleScope({ companyId: key });
      } else {
        notify({ type: 'warning', message: selectable?.message });
        handleScope({ companyId: null });
      }
    } else {
      handleScope({ companyId: null });
    }

    history.push(redirect.join('/'));
  };

  return (
    <Searcher
      data={resolveData}
      value={resolveValue}
      searching={loading}
      placeholder={t('company.select')}
      handleAdd={handleCompany}
      handleSearch={handleSearch}
    />
  );
};

CompanySelector.propTypes = {
  pathname: PropTypes.string,
  history: PropTypes.object,
  scope: PropTypes.object,
  handleScope: PropTypes.func,
};

export default CompanySelector;
