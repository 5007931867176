import React from 'react';
import * as PropTypes from 'prop-types';
import { Select } from 'antd';

const selectOptions = ({
  data, list, value, name
}) => {
  if (data && list && Array.isArray(data[list])) {
    return data[list].map((x) => (
      <Select.Option key={x[value]} value={x[value]} title={x[name]}>
        {x[name]}
      </Select.Option>
    ));
  }

  if (data && !list && Array.isArray(data)) {
    return data.map((x) => (
      <Select.Option key={x[value]} value={x[value]} title={x[name]}>
        {x[name]}
      </Select.Option>
    ));
  }

  return <Select.Option key={0} value={0}>empty</Select.Option>;
};

selectOptions.propTypes = {
  data: PropTypes.object.isRequired || PropTypes.array.isRequired,
  list: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default selectOptions;
