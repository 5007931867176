import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Button, Col, Divider, Icon, Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import { iconResolver } from '../../utils';
import ActionButton from './ActionButton';

const AttachmentList = ({ attachments, handleDelete, readonly }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={8} type="flex" className="custom-container-padding">
      {attachments.map(({ file, id }) => (
        <Col key={id} span={24}>
          <Row gutter={8} type="flex" key={id}>
            <Col span={18} className="custom-form-vertical-margin">
              <p className="custom-overflow-ellipsis custom-text-left">{file?.filename}</p>
            </Col>
            <Col span={2} className="custom-form-vertical-margin">
              <Icon type={iconResolver({ extension: file?.extension })} />
            </Col>
            <Col span={2} className="custom-form-vertical-margin">
              <a
                href={file.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button shape="circle" icon="download" />
              </a>
            </Col>
            <Col span={2} className="custom-form-vertical-margin">
              <ActionButton
                key="delete"
                confirm
                confirmText={t('exhaustion.fileConfirmDelete')}
                handleClick={() => handleDelete(id)}
                icon="delete"
                text={t('exhaustion.fileDelete')}
                type="danger"
                readonly={readonly}
              />
            </Col>
            <Divider className="custom-divider" />
          </Row>
        </Col>
      ))}
    </Row>
  );
};

AttachmentList.propTypes = {
  readonly: PropTypes.bool,
  attachments: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default AttachmentList;
