import React from 'react';
import * as PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import ActionButton from './ActionButton';

const TitleActions = ({ title, actions }) => (
  <Row type="flex" className="custom-title-border">
    <Col span={18}>
      <h3 className="custom-title-content">{title}</h3>
    </Col>
    <Col span={6}>
      <Row gutter={8} type="flex" justify="end" className="custom-up-button">
        {actions?.map((props, index) => (
          <Col span={4} key={index}>
            <ActionButton
              {...props}
            />
          </Col>
        ))}
      </Row>
    </Col>
  </Row>
);

TitleActions.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
};

export default TitleActions;
