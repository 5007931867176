import { lazy } from 'react';

const ivc = {
  adminChecklist: lazy(() => import('../../../pages/Ivc/Admin/Checklist.js')),
  adminChecklists: lazy(() => import('../../../pages/Ivc/Admin/Checklists.js')),
  adminCreateVisit: lazy(() => import('../../../pages/Ivc/Admin/CreateVisit.js')),
  adminEditVisit: lazy(() => import('../../../pages/Ivc/Admin/EditVisit.js')),
  adminMonth: lazy(() => import('../../../pages/Ivc/Admin/Month.js')),
  adminVisits: lazy(() => import('../../../pages/Ivc/Admin/Visits.js')),
  checklistBpmaa: lazy(() => import('../../../pages/Ivc/Visit/ChecklistBpmaa.js')),
  checklistLabeled: lazy(() => import('../../../pages/Ivc/Visit/ChecklistLabeled.js')),
  executeVisit: lazy(() => import('../../../pages/Ivc/Visit/ExecuteVisit.js')),
  ivcTemplates: lazy(() => import('../../../pages/Ivc/Visit/Templates.js')),
  ivcInformation: lazy(() => import('../../../pages/Ivc/Information/Information.js')),
  manualSigning: lazy(() => import('../../../pages/Ivc/Visit/ManualSigning.js')),
  sanityMeasure: lazy(() => import('../../../pages/Ivc/Visit/SanityMeasure.js')),
  sanitaryMeasureDetail: lazy(() => import('../../../pages/Ivc/SanitaryMeasure/SanitaryMeasureDetail.js')),
  sampling: lazy(() => import('../../../pages/Ivc/Visit/Sampling.js')),
  searchFilesIvc: lazy(() => import('../../../pages/Ivc/Admin/SearchFilesIvc.js')),
  signCharters: lazy(() => import('../../../pages/Security/Ivc/SignCharters.js')),
  suggestedSchedule: lazy(() => import('../../../pages/Ivc/Admin/SuggestedSchedule.js')),
  suggestedSchedules: lazy(() => import('../../../pages/Ivc/Admin/SuggestedSchedules.js')),
  visit: lazy(() => import('../../../pages/Ivc/Visit/Visit')),
  visitMyVisits: lazy(() => import('../../../pages/Ivc/Visit/MyVisits')),
  viewSanitaryMeasure: lazy(() => import('../../../pages/Ivc/Information/ViewSanitaryMeasure.js')),
  viewSanitaryMeasureDetail: lazy(() => import('../../../pages/Ivc/SanitaryMeasure/ViewSanitaryMeasure')),
  viewSanitaryMeasureVisit: lazy(() => import('../../../pages/Ivc/Information/ViewSanitaryMeasureVisit.js')),
  viewDetailVisit: lazy(() => import('../../../pages/Ivc/Information/ViewDetailVisit.js')),
  viewSample: lazy(() => import('../../../pages/Ivc/Visit/ViewSample.js')),
  viewSampleDetail: lazy(() => import('../../../pages/Ivc/Visit/ViewSampleDetail.js')),
};

const asyncFiles = {
  activate: lazy(() => import('../../../pages/Security/Activate.js')),
  agent: lazy(() => import('../../../pages/Security/Agent.js')),
  agents: lazy(() => import('../../../pages/Security/Agents.js')),
  certificates: lazy(() => import('../../../pages/AnimalFeed/Certificate/Certificates.js')),
  checkoutRequest: lazy(() => import('../../../pages/Security/CheckoutRequest.js')),
  checkoutResult: lazy(() => import('../../../pages/Security/CheckoutResult.js')),
  company: lazy(() => import('../../../pages/AnimalFeed/Company/Company.js')),
  companyRequest: lazy(() => import('../../../pages/AnimalFeed/Company/CompanyRequest.js')),
  companies: lazy(() => import('../../../pages/AnimalFeed/Company/Companies.js')),
  composition: lazy(() => import('../../../pages/AnimalFeed/Admin/Composition.js')),
  exhausts: lazy(() => import('../../../pages/AnimalFeed/Product/Exhausts.js')),
  exhaustionReview: lazy(() => import('../../../pages/AnimalFeed/Product/ExhaustionReview.js')),
  forgotPassword: lazy(() => import('../../../pages/Security/ForgotPassword.js')),
  freeSaleRequest: lazy(() => import('../../../pages/AnimalFeed/Certificate/FreeSaleRequest.js')),
  freeSaleReview: lazy(() => import('../../../pages/AnimalFeed/Certificate/FreeSaleReview.js')),
  freeSalePlantsRequest: lazy(() => import('../../../pages/AnimalFeed/Certificate/FreeSalePlantsRequest.js')),
  freeSalePlantsReview: lazy(() => import('../../../pages/AnimalFeed/Certificate/FreeSalePlantsReview.js')),
  home: lazy(() => import('../../../pages/Security/Layout/Home.js')),
  importer: lazy(() => import('../../../pages/AnimalFeed/Product/Importer.js')),
  importerRequest: lazy(() => import('../../../pages/AnimalFeed/Product/ImporterRequest.js')),
  deleteImporterRequest: lazy(() => import('../../../pages/AnimalFeed/Product/DeleteImporterRequest.js')),
  deleteImporterReview: lazy(() => import('../../../pages/AnimalFeed/Product/DeleteImporterReview.js')),
  extensionReview: lazy(() => import('../../../pages/AnimalFeed/Product/ExtensionReview.js')),
  importerReview: lazy(() => import('../../../pages/AnimalFeed/Product/ImporterReview.js')),
  importers: lazy(() => import('../../../pages/AnimalFeed/Product/Importers.js')),
  deleteImporters: lazy(() => import('../../../pages/AnimalFeed/Product/DeleteImporter.js')),
  ingredient: lazy(() => import('../../../pages/AnimalFeed/Admin/Ingredient.js')),
  login: lazy(() => import('../../../pages/Security/Login.js')),
  maintenance: lazy(() => import('../../../pages/Security/Other/Maintenance.js')),
  menu: lazy(() => import('../../../pages/Security/Menu.js')),
  menus: lazy(() => import('../../../pages/Security/Menus.js')),
  myRequests: lazy(() => import('../../../pages/Security/MyRequests.js')),
  noRegisterRequest: lazy(() => import('../../../pages/AnimalFeed/Certificate/NoRegisterRequest.js')),
  noRegisterReview: lazy(() => import('../../../pages/AnimalFeed/Certificate/NoRegisterReview.js')),
  notFound: lazy(() => import('../../../pages/Security/Other/NotFound404.js')),
  nutrient: lazy(() => import('../../../pages/AnimalFeed/Admin/Nutrient.js')),
  ownership: lazy(() => import('../../../pages/AnimalFeed/Product/Ownership.js')),
  ownershipReview: lazy(() => import('../../../pages/AnimalFeed/Product/OwnershipReview.js')),
  ownerships: lazy(() => import('../../../pages/AnimalFeed/Product/Ownerships.js')),
  payments: lazy(() => import('../../../pages/Security/Payments.js')),
  publicReports: lazy(() => import('../../../pages/Security/PublicReports.js')),
  product: lazy(() => import('../../../pages/AnimalFeed/Product/Product.js')),
  productAdviser: lazy(() => import('../../../pages/AnimalFeed/Product/ProductAdviser.js')),
  productExhaustion: lazy(() => import('../../../pages/AnimalFeed/Product/ProductExhaustion.js')),
  productRequest: lazy(() => import('../../../pages/AnimalFeed/Product/ProductRequest.js')),
  productReview: lazy(() => import('../../../pages/AnimalFeed/Product/ProductReview.js')),
  products: lazy(() => import('../../../pages/AnimalFeed/Product/Products.js')),
  profile: lazy(() => import('../../../pages/Security/Profile.js')),
  records: lazy(() => import('../../../pages/Security/Records.js')),
  register: lazy(() => import('../../../pages/Security/Register.js')),
  reports: lazy(() => import('../../../pages/Security/Reports.js')),
  reset: lazy(() => import('../../../pages/Security/Reset.js')),
  result: lazy(() => import('../../../pages/Security/Other/Result.js')),
  role: lazy(() => import('../../../pages/Security/Role.js')),
  roles: lazy(() => import('../../../pages/Security/Roles.js')),
  searchResolution: lazy(() => import('../../../pages/Security/SearchResolution.js')),
  species: lazy(() => import('../../../pages/AnimalFeed/Admin/Species.js')),
  user: lazy(() => import('../../../pages/Security/User.js')),
  users: lazy(() => import('../../../pages/Security/Users.js')),
  searchVerificationCode: lazy(() => import('../../../pages/Security/SearchVerificationCode.js')),
  signatureRequest: lazy(() => import('../../../pages/Signature/SignatureRequest.js')),
  signatureRequestList: lazy(() => import('../../../pages/Signature/SignatureRequestsList.js')),
  ...ivc,
};

export default asyncFiles;
