import React from 'react';
import * as PropTypes from 'prop-types';
import { Menu, Icon, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { MY_MENU } from '../../../graphql';
import { Loading } from '../../Shared';

const MenuPrimary = ({
  history, pathname, menus, scope
}) => {
  const { t } = useTranslation();
  const { companyId } = scope || {};

  const { data, loading } = useQuery(MY_MENU, { fetchPolicy: 'network-only' });

  const myMenu = data?.myMenu.reduce((result, { id, ...rest }) => {
    const menu = menus.find(({ key }) => key === id);
    return result.concat([{ ...menu, ...rest, id }]);
  }, []);

  return (
    <Loading loading={loading} custom="custom-component-spin">
      <Menu mode="inline" selectedKeys={[pathname]}>
        {myMenu?.map(
          ({
            path, submenu, icon, label, key, description
          }, index) => (path ? (
            <Menu.Item key={key}>
              <Tooltip placement="right" title={description}>
                <a href={path.replace('$[{companyId}]', companyId || '_')}>
                  {icon && <Icon type={icon} className="custom-menu-icon" />}
                  <span className="nav-text">{label}</span>
                </a>
              </Tooltip>
            </Menu.Item>
          ) : (
              submenu?.length > 0 && (
                <Menu.SubMenu
                  key={index}
                  title={(
                    <span>
                      {icon && (
                        <Icon type={icon} className="custom-menu-icon" />
                      )}
                      <span>{label}</span>
                    </span>
                  )}
                >
                  {submenu.map((y) => (
                    <Menu.Item key={y.key} className="custom-menu-item">
                      <a
                        href={y.path.replace('$[{companyId}]', companyId || '_')}
                      >
                        {y.icon && (
                          <Icon type={icon} className="custom-menu-icon" />
                        )}
                        <span className="nav-text">{t(y.label)}</span>
                      </a>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
            )
          ))
        )}
      </Menu>
    </Loading>
  );
};

MenuPrimary.propTypes = {
  pathname: PropTypes.string,
  history: PropTypes.object,
  scope: PropTypes.object,
  menus: PropTypes.array,
};

export default MenuPrimary;
