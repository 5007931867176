import React from 'react';
import * as PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { ToolbarButton } from '../../../components/Shared';
import blockTypes from './blockTypes';

const BlockStyleControls = ({ state, readonly, handleToggle }) => {
  const { t } = useTranslation();

  const selection = state?.getSelection();
  const blockType = state?.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  return (
    <div className="richEditor-controls" key="block">
      {blockTypes(t).map(({
        style, label, icon, divider
      }) => (
        (!divider
        && (
        <ToolbarButton
          key={label}
          active={style === blockType}
          label={label}
          icon={icon}
          readonly={readonly}
          handleToggle={() => handleToggle(style)}
          apply={style}
        />
        ))
        || <Divider key={label} type="vertical" />
      ))}
    </div>
  );
};
BlockStyleControls.propTypes = {
  state: PropTypes.object,
  handleToggle: PropTypes.func
};

export default BlockStyleControls;
