import { gql } from 'apollo-boost';

const legal = `
  representative {
    id
    dniType
    dni
    firstName
    lastName
    email
    address
    city {
      id
      state {
        id
      }
    }
    telephone
    cellphone
  }
`;

const information = `
  old
  oldDocuments {
    id
    type
    resolution
    issuedAt
    year
    file {
      id
      filename
      extension
      path
      url
    }
  }
  name
  dniType
  dni
  commercialName
  city {
    id
    state {
      id
    }
  }
  village
  address
  telephone
  cellphone
  email
  web
  taxpayer
  selfRetainer
  responsibleVAT
  regime
`;

const notifications = `
  sendNotificationsPhysicalAddress
  sendNotificationsEmail
  notificationsAddress
  notificationsEmail
`;

const activities = `
  activities {
    id
    key
    speciesIds
    processesIds
    productTypesIds
    typesIds
  }
  providers {
    id
    name
    email
    countryId
    city
    telephone
    contactName
  }
`;

const plants = `
  oldPlants {
    id
    type
    resolution
    year
    issuedAt
    file {
      id
      filename
      extension
      path
      url
    }
  }
  plants {
    id
    name
    cityId
    city {
      id
      state {
        id
      }
    }
    village
    isWarehouse
    address
    telephone
    contactName
  }
`;

const relationship = `
  producerCompanies {
    id
    name
    dni
    dniType
    producerId
    producer {
      id
      name
      dni
      dniType
    }
    consumer {
      id
      name
      dni
      dniType
    }
    createdAt
    status
  }
  consumerCompanies {
    id
    name
    dni
    dniType
    producerId
    producer {
      id
      name
      dni
      dniType
    }
    consumer {
      id
      name
      dni
      dniType
    }
    createdAt
    status
  }
`;

const advisers = `
  advisers {
    id
    adviser {
      id
      dniType
      dni
      firstName
      lastName
      email
    }
    dniType
    dni
    firstName
    lastName
    email
    status
  }
`;

const terms = `
  acceptTerms
  privacy
`;

const security = `
  activatable
  cancellable
  editable
  requireUpdate
  updatableUntil
  outOfDate
`;

const companyFields = `
  id
  ${legal}
  ${information}
  ${notifications}
  ${activities}
  ${plants}
  ${relationship}
  ${advisers}
  ${terms}
  ${security}
`;

export const ACTIVATE_COMPANY = gql`
  mutation ActivateCompany($id: ID!) {
    activateCompany(id: $id)
  }
`;

export const CANCEL_ALERT_COMPANY = gql`
    mutation CancelAlertCompany($id: ID!) {
        cancelAlertCompany(companyId: $id)
    }
`;

export const CANCEL_COMPANY = gql`
  mutation CancelCompany($id: ID!, $input: InputCancelCompany!) {
    cancelCompany(id: $id, input: $input)
  }
`;

export const COMPANY = gql`
  query Company($id: ID!) {
    company(id: $id) {
      ${companyFields}
    }
  }
`;

export const COMPANY_ADVISER = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      advisers {
        id
        adviser {
          id
          dniType
          dni
          firstName
          lastName
          email
        }
        dniType
        dni
        firstName
        lastName
        email
        status
      }
    }
  }
`;


export const COMPANY_ALERT = gql`
    query CompanyAlert($id: ID!, $input: [InputCan!]!) {
        can (input: $input) {
            permission
            result            
        }
        company(id: $id) {
            id
            alertBlocked
        }
    }
`;

export const COMPANY_OLD_DOCUMENTS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      oldDocuments {
        id
        type
        resolution
        year
        issuedAt
        file {
          id
          filename
          extension
          path
        }
      }
    }
  }
`;

export const COMPANY_PLANTS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      plants {
        id
        name
        city {
          id
          state {
            id
          }
        }
        isWarehouse
        address
        telephone
        contactName
      }
      oldPlants {
        id
        type
        resolution
        year
        issuedAt
        file {
          filename
        }
      }
    }
  }
`;

export const COMPANY_PROVIDERS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      providers {
        id
        name
        email
        countryId
        city
        telephone
        contactName
      }
    }
  }
`;

export const COMPANY_RELATIONSHIP = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      producerCompanies {
        id
        name
        dni
        dniType
        producer {
          id
          name
          dni
          dniType
        }
        consumer {
          id
          name
          dni
          dniType
        }
        createdAt
        status
      }
    }
  }
`;

export const COMPANY_SCOPE = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      name
      selectable {
        can
        message
      }
    }
  }
`;

export const COMPANIES = gql`
  query Companies($input: InputCompanies) {
    companies(input: $input) {
      id
      name
      dniType
      dni
      selectable {
        can
        message
      }
    }
  }
`;

export const COMPANIES_FILTER_DATA = gql`
  query CompaniesFilterData {
    companiesFilterData {
      statuses {
        id
        description
      }
    }
  }
`;

export const CREATE_ALERT_COMPANY = gql`
    mutation CreateAlertCompany($id: ID!, $comment: String!) {
        createAlertCompany(companyId: $id, comment: $comment) 
    }
`;

export const PAGINATE_COMPANIES = gql`
  query PaginateCompanies(
    $input: InputCompanies
    $pagination: InputPagination
  ) {
    paginateCompanies(input: $input, pagination: $pagination) {
      total
      items {
        id
        name
        dniType
        dni
        displayable
        status
        statusDescription
        activatableUntil
      }
    }
  }
`;

export const SEARCH_COMPANIES = gql`
  query SearchCompanies($input: SearchCompaniesInput!) {
    searchCompanies(input: $input) {
      id
      dni
      name
      dniType
      dniTypeDescription
      status
    }
  }
`;

export const COMPANY_CONSUMERS = gql`
  query Company($companyId: ID!, $input: InputProducerCompanies) {
    company(id: $companyId) {
      id
      name
      consumerCompanies(input: $input) {
        id
        name
        createdAt
        status
        owner
        order
        author {
          dni
          dniType
          firstName
          lastName
        }
        producer {
          id
          name
        }
        consumer {
          id
          name
        }
      }
    }
  }
`;

export const PLANTS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      plants {
        id
        name
        address
        isWarehouse
        city {
          id
          name
          state {
            id
            name
          }
        }
      }
    }
  }
`;

export const ACCEPT_CONSUMER = gql`
  mutation AcceptProducerCompany($id: ID!, $companyId: ID) {
    acceptProducerCompany(id: $id, companyId: $companyId)
  }
`;

export const REJECT_CONSUMER = gql`
  mutation RejectProducerCompany($id: ID!, $comment: String!, $companyId: ID) {
    rejectProducerCompany(id: $id, comment: $comment, companyId: $companyId)
  }
`;

export const FINISH_PRODUCER = gql`
  mutation FinishProducer($id: ID!, $companyId: ID!) {
    finishConsumer(id: $id, companyId: $companyId)
  }
`;

export const FINISH_CONSUMER = gql`
  mutation FinishProducer($id: ID!, $companyId: ID!) {
    finishProducer(id: $id, companyId: $companyId)
  }
`;
