import React from 'react';
import * as PropTypes from 'prop-types';

const RichTextReader = ({ current }) => {
  const images = current
    ?.getBlockMap()
    .filter((block) => !!block.getEntityAt(0));

  return (
    <div>
      <p>{current.getPlainText()}</p>
      <div>
        {images.reduce((result, next) => {
          const entity = next.getEntityAt(0);
          const content = current.getEntity(entity);
          const data = content.getData();

          return result.concat([
            <a href={data.src} target="_blank" rel="noopener noreferrer">
              <img
                alt={entity}
                src={data.src}
                className="custom-preview-image"
              />
            </a>,
          ]);
        }, [])}
      </div>
    </div>
  );
};

RichTextReader.propTypes = {
  current: PropTypes.object,
};

export default RichTextReader;
