export const logout = (cache) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  sessionStorage.removeItem('scope');

  const data = {
    logged: false,
    user: null,
    scope: null,
    __typename: 'LoginResponse',
  };

  if (cache) {
    cache.reset();
    cache.writeData({ data: { checkAuthentication: data } });
  }
  return data;
};
