import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, InputNumber, Modal, Row, Select, Switch
} from 'antd';
import { places } from '../../config/Shared/Address';
import { formMultiRowLayout, formValidations } from '../../config/Shared/Constants';
import InputTooltip from './InputTooltip';
import { filterCharacters, lettersCharacters, numbersCharacters } from '../../utils';

const reduceAddress = ({ result, check }, value) => {
  if (!value || value === '') {
    return { result: result.concat([value]), check: false };
  }
  if (!check && typeof value === 'string' && value.match(lettersCharacters)) {
    return { result: result.concat([value]), check: true };
  }
  if (check && typeof value === 'string' && value.match(lettersCharacters)) {
    const next = [...result];
    next[next.length - 1] = `${next[next.length - 1]} ${value}`;
    return { result: next, check: true };
  }
  return { result: result.concat([value]), check: false };
};
const parseAddress = (address) => {
  const response = (address?.replace(' #', '').split(' ')
  || [undefined, undefined, undefined, undefined, undefined, undefined, '']).reduce(reduceAddress, { result: [], check: false });
  return response.result;
};
const Address = ({
  visible, address, handleCancel, handleOk, form
}) => {
  const [rural, setRural] = useState(false);
  const { t } = useTranslation();

  const [addressType, principalNumber, principalSuffix, secondaryNumber, secondarySuffix, finalNumber, complement] = parseAddress(undefined);

  const buildAddress = `${form.getFieldValue('addressType') || ''} ${form.getFieldValue('principalNumber') || ''} ${form.getFieldValue('principalSuffix') || ''} # ${form.getFieldValue('secondaryNumber') || ''} ${form.getFieldValue('secondarySuffix') || ''} ${form.getFieldValue('finalNumber') || ''} ${form.getFieldValue('complement') || ''}`;

  const onOk = () => {
    form.validateFields((err, values) => {
      if (!err) {
        const fullAddress = rural ? values.ruralAddress : `${values.addressType} ${values.principalNumber} ${values.principalSuffix?.replace(' ', '').replace('#', '') || ''} # ${values.secondaryNumber || ''} ${values.secondarySuffix?.replace(' ', '').replace('#', '') || ''} ${values.finalNumber || ''} ${values.complement?.replace(' ', '').replace('#', '') || ''}`;
        handleOk(fullAddress);
      }
    });
  };

  return (
    <Modal
      visible={visible}
      title={t('address.title')}
      okText={t('common.ok')}
      cancelText={t('common.cancel')}
      onCancel={handleCancel}
      onOk={onOk}
      afterClose={form.resetFields}
      closable={false}
      maskClosable={false}
      width="90%"
    >
      <Form>
        <Row>
          <Col span={4}>
            <Form.Item label={t('address.rural')} {...formMultiRowLayout}>
              {
                form.getFieldDecorator('isRural', {
                  initialValue: rural,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={() => setRural(!rural)}
                    checkedChildren={t('common.yes')}
                    unCheckedChildren={t('common.no')}
                  />
                )
              }
            </Form.Item>
          </Col>
          {
            rural && (
              <Col span={10}>
                <Form.Item label={t('address.ruralAddress')} {...formMultiRowLayout}>
                  {
                    form.getFieldDecorator('ruralAddress', {
                      initialValue: address,
                      rules: [{
                        min: 5,
                        required: true,
                        message: t('rules.ruralAddressRequired')
                      }]
                    })(
                      <Input
                        minLength={formValidations(t).min.address}
                        maxLength={formValidations(t).max.number}
                        suffix={<InputTooltip icon="info-circle" text={t('address.ruralHelp')} />}
                      />
                    )
                  }
                </Form.Item>
              </Col>
            )
          }
        </Row>
        {
          !rural && (
            <>
              <Row type="flex" gutter={8}>
                <Col span={8}>
                  <Form.Item label={t('address.type')} {...formMultiRowLayout}>
                    {form.getFieldDecorator('addressType', {
                      initialValue: addressType,
                      rules: [{ required: true, message: t('rules.addressTypeRequired') }]
                    })(
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="custom-full-width"
                      >
                        {places.map((x) => t(`address.${x}`)).sort().map((x) => (
                          <Select.Option value={x} key={x}>
                            {x}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('address.principalNumber')} {...formMultiRowLayout}>
                    {form.getFieldDecorator('principalNumber', {
                      initialValue: typeof principalNumber === 'string' ? parseInt(principalNumber) : principalNumber,
                      rules: [{
                        required: true,
                        message: t('rules.addressPrincipalNumberRequired')
                      }, formValidations(t).rules.type.integer]
                    })(
                      <InputNumber
                        className="custom-full-width"
                        max={formValidations(t).max.number}
                        min={formValidations(t).min.number}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('address.suffix')} {...formMultiRowLayout}>
                    {form.getFieldDecorator('principalSuffix', {
                      initialValue: principalSuffix,
                      rules: []
                    })(
                      <Input
                        maxLength={formValidations(t).max.input}
                        onChange={(e) => {
                          e.target.value = filterCharacters(e, numbersCharacters);
                        }}
                        suffix={<InputTooltip icon="info-circle" text={t('address.suffixHelp')} />}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" gutter={8}>
                <Col span={8}>
                  <Form.Item label={t('address.secondaryNumber')} {...formMultiRowLayout}>
                    {form.getFieldDecorator('secondaryNumber', {
                      initialValue: typeof secondaryNumber === 'string' ? parseInt(secondaryNumber) : secondaryNumber,
                      rules: [formValidations(t).rules.type.integer]
                    })(
                      <InputNumber className="custom-full-width" max={formValidations(t).max.number} min={formValidations(t).min.number} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('address.suffix')} {...formMultiRowLayout}>
                    {form.getFieldDecorator('secondarySuffix', {
                      initialValue: secondarySuffix,
                      rules: []
                    })(
                      <Input
                        maxLength={formValidations(t).max.input}
                        onChange={(e) => {
                          e.target.value = filterCharacters(e, numbersCharacters);
                        }}
                        suffix={<InputTooltip icon="info-circle" text={t('address.suffixHelp')} />}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('address.finalNumber')} {...formMultiRowLayout}>
                    {form.getFieldDecorator('finalNumber', {
                      initialValue: typeof finalNumber === 'string' ? parseInt(finalNumber) : finalNumber,
                      rules: []
                    })(
                      <Input
                        className="custom-full-width"
                        max={formValidations(t).max.number}
                        min={formValidations(t).min.number}
                        onChange={(e) => {
                          e.target.value = filterCharacters(e, lettersCharacters);
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" gutter={8}>
                <Col span={8}>
                  <Form.Item label={t('address.complement')} {...formMultiRowLayout}>
                    {form.getFieldDecorator('complement', {
                      initialValue: complement,
                      rules: []
                    })(
                      <Input
                        maxLength={formValidations(t).max.input}
                        suffix={<InputTooltip icon="info-circle" text={t('address.complementHelp')} />}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label={t('address.address')} {...formMultiRowLayout}>
                    <Input
                      disabled
                      value={buildAddress}
                      suffix={<InputTooltip icon="info-circle" text={t('address.addressHelp')} />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )
        }
      </Form>
    </Modal>
  );
};

Address.propTypes = {
  visible: PropTypes.bool,
  address: PropTypes.string,
  form: PropTypes.object,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func
};

export default Form.create({ name: 'address' })(Address);
