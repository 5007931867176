const user = {
  type: {
    agent: 'agent',
    user: 'user',
  },
  warning: {
    agent: {
      cancelCompany: 'company-cancellation-warning-agent',
      cancelProduct: 'product-cancellation-warning-agent',
    },
    user: {
      cancelCompany: 'company-cancellation-warning-user',
      cancelProduct: 'product-cancellation-warning-user',
    },
  },
};

export default user;
