const menus = {
  primary: [
    {
      icon: 'home',
      key: 'home',
      path: '/home/$[{companyId}]',
    },
    {
      icon: 'user',
      key: 'profile',
      path: '/profile/$[{companyId}]',
    },
    {
      icon: 'shop',
      key: 'companies',
      path: '/companies/$[{companyId}]',
    },

    {
      icon: 'audit',
      key: 'certificates',
      path: '/certificates/$[{companyId}]',
    },

    {
      icon: 'unordered-list',
      key: 'records',
      path: '/records/$[{companyId}]',
    },
    {
      icon: 'gold',
      key: 'products',
      path: '/products/$[{companyId}]',
    },
    {
      icon: 'team',
      key: 'users',
      path: '/users/$[{companyId}]',
    },
    {
      icon: 'lock',
      key: 'roles',
      path: '/roles/$[{companyId}]',
    },
    {
      icon: 'snippets',
      key: 'myRequests',
      path: '/myRequests/$[{companyId}]',
    },
    {
      icon: 'cluster',
      key: 'importers',
      path: '/importers/$[{companyId}]',
    },
    {
      icon: 'team',
      key: 'agents',
      path: '/agents/$[{companyId}]',
    },
    {
      icon: 'gold',
      key: 'payments',
      path: '/payments/$[{companyId}]',
    },
    {
      icon: 'cluster',
      key: 'menus',
      path: '/menus/$[{companyId}]',
    },
    {
      icon: 'minus-circle',
      key: 'importer-deletion',
      path: '/deleteImporters/$[{companyId}]',
    },
    {
      icon: 'tag',
      key: 'exhaustion',
      path: '/exhausts/$[{companyId}]',
    },
    {
      icon: 'cluster',
      key: 'nutrients',
      path: '/composition/$[{companyId}]/1',
    },
    {
      icon: 'radar-chart',
      key: 'species',
      path: '/species/$[{companyId}]',
    },
    {
      icon: 'export',
      key: 'transfer-ownership',
      path: '/ownerships/$[{companyId}]',
    },
    {
      icon: 'area-chart',
      key: 'reports',
      path: '/reports/$[{companyId}]',
    },
    {
      icon: 'audit',
      key: 'checklists',
      path: '/adminChecklists',
    },
    {
      icon: 'calendar',
      key: 'visitSchedules',
      path: '/adminVisits/',
    },
    {
      icon: 'calendar',
      key: 'visits',
      path: '/visitMyVisits/',
    },
    {
      icon: 'clock-circle-o',
      key: 'suggestedSchedule',
      path: '/suggestedSchedules'
    },
    {
      icon: 'book',
      key: 'IVCTemplates',
      path: '/ivcTemplates'
    },
    {
      icon: 'book',
      key: 'IVCDocuments',
      path: '/searchFilesIvc'
    },
    {
      icon: 'snippets',
      key: 'IVCInfo',
      path: '/informationIvc/$[{companyId}]'
    },
    {
      icon: 'schedule',
      key: 'sanitaryMeasures',
      path: '/sanitaryMeasures'
    },
    {
      icon: 'schedule',
      key: 'samples',
      path: '/viewSample'
    },
    {
      icon: 'right-square',
      key: 'signatureRequest',
      path: '/signatureRequest'
    },
    {
      icon: 'pic-left',
      key: 'signatureRequestList',
      path: '/signatureRequestList'
    }
  ],
  header: [],
};

export default menus;
