import { gql } from 'apollo-boost';

export const PROCESSES = gql`
  query Processes {
    processes {
      id
      name
    }
  }
`;
