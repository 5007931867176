import { gql } from 'apollo-boost';

export const CATEGORIES = gql`
  query Categories($type: Int) {
    categories(type: $type) {
      id
      name
    }
  }
`;

export const CATEGORIES_SUBCATEGORIES = gql`
  query Categories($type: Int) {
    categories(type: $type) {
      id
      name
      subcategories {
        id
        name
      }
    }
  }
`;
