import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { Select, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { formValidations } from '../../config/Shared/Constants';
import Loading from './Loading';

const Searcher = forwardRef(
  (
    {
      searching,
      readonly,
      data,
      placeholder,
      handleSearch,
      handleAdd,
      value = [],
    },
    ref
  ) => {
    const { t } = useTranslation();
    const onSearch = debounce(handleSearch, 800);

    return (
      <Select
        allowClear
        className="custom-full-width"
        disabled={readonly}
        filterOption={false}
        labelInValue
        maxLength={formValidations(t).max.input}
        mode="default"
        notFoundContent={
          searching ? (
            <Loading loading custom="custom-component-spin" />
          ) : (
            <Empty />
          )
        }
        onSearch={onSearch}
        onFocus={() => handleSearch('')}
        onChange={handleAdd}
        placeholder={placeholder}
        showSearch
        value={value}
      >
        {data.map(({ key, label }) => (
          <Select.Option key={key} value={key}>
            {label}
          </Select.Option>
        ))}
      </Select>
    );
  }
);

Searcher.propTypes = {
  readonly: PropTypes.bool,
  searching: PropTypes.bool,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleSearch: PropTypes.func,
  handleAdd: PropTypes.func,
};

export default Searcher;
