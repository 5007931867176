import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, Row, Col } from 'antd';

const CheckOption = ({
  title, readonly, value, checked, full, handleCheck
}) => (
  <Row key={value}>
    <Col span={24}>
      <Checkbox
        checked={checked}
        value={value}
        key={value}
        onClick={handleCheck}
        className={`${full ? 'custom-full-width' : ''} custom-vertical-check`}
        disabled={readonly}
      >
        {title}
      </Checkbox>
    </Col>
  </Row>

);

CheckOption.propTypes = {
  checked: PropTypes.bool,
  full: PropTypes.bool,
  readonly: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  handleCheck: PropTypes.func
};

export default CheckOption;
