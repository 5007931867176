import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

const Register = ({ history }) => {
  const { t } = useTranslation();
  return (<Button onClick={() => history.push('/raw/login')} type="primary">{t('activate.goLogin')}</Button>);
};

Register.propTypes = {
  history: PropTypes.object
};

export default Register;
