import { gql } from 'apollo-boost';

export const ADMIN_CREATE_SPECIE = gql`
  mutation CreateSpecie($input: InputSpecie!) {
    createSpecie(input: $input) {
      id
    }
  }
`;

export const ADMIN_CREATE_STAGE = gql`
  mutation CreateStage($input: InputStage!) {
    createStage(input: $input) {
      id
    }
  }
`;

export const ADMIN_CREATE_SUBSPECIE = gql`
  mutation CreateSubSpecie($input: InputSubSpecie!) {
    createSubSpecie(input: $input) {
      id
    }
  }
`;

export const ADMIN_DELETE_SPECIE = gql`
  mutation DeleteSpecie($id: ID!) {
    deleteSpecie(id: $id) {
      id
    }
  }
`;

export const ADMIN_DELETE_STAGE = gql`
  mutation DeleteStage($id: ID!) {
    deleteStage(id: $id) {
      id
    }
  }
`;

export const ADMIN_DELETE_SUBSPECIE = gql`
  mutation DeleteSubSpecie($id: ID!) {
    deleteSubSpecie(id: $id) {
      id
    }
  }
`;

export const ADMIN_EDIT_SPECIE = gql`
  mutation UpdateSpecie($id: ID!, $input: InputSpecie!) {
    updateSpecie(id: $id, input: $input) {
      id
    }
  }
`;

export const ADMIN_EDIT_STAGE = gql`
  mutation UpdateSpecie($id: ID!, $input: InputStage!) {
    updateStage(id: $id, input: $input) {
      id
    }
  }
`;

export const ADMIN_EDIT_SUBSPECIE = gql`
  mutation UpdateSubSpecie($id: ID!, $input: InputSubSpecie!) {
    updateSubSpecie(id: $id, input: $input) {
      id
    }
  }
`;

export const ADMIN_SPECIES = gql`
  query PaginateSpecies(
    $filter: InputFilterSpecies
    $pagination: InputPagination
  ) {
    paginateSpecies(filter: $filter, pagination: $pagination) {
      total
      items {
        id
        name
        editable
        deletetable
      }
    }
  }
`;

export const ADMIN_STAGES = gql`
  query PaginateStages(
    $filter: InputFilterStages
    $pagination: InputPagination
  ) {
    paginateStages(filter: $filter, pagination: $pagination) {
      total
      items {
        id
        name
        editable
        deletetable
      }
    }
  }
`;

export const ADMIN_SUBSPECIES = gql`
  query PaginateSubSpecies(
    $filter: InputFilterSubSpecies
    $pagination: InputPagination
  ) {
    paginateSubSpecies(filter: $filter, pagination: $pagination) {
      total
      items {
        id
        name
        editable
        deletetable
      }
    }
  }
`;

export const SPECIES = gql`
  query Species {
    species {
      id
      name
    }
  }
`;

export const COMPANY_SPECIES = gql`
  query CompanySpecies($companyId: ID!) {
    company(id: $companyId) {
      id
      species {
        id
        name
      }
    }
  }
`;

export const SUBSPECIES = gql`
  query SubSpecies($specieId: ID!) {
    subspecies(specieId: $specieId) {
      id
      name
    }
  }
`;

export const SPECIESIDS = gql`
  query SpeciesIDs($ids: [ID]) {
    species(ids: $ids) {
      id
      name
    }
  }
`;

export const SUBSPECIESID = gql`
  query SubSpeciesIDs($ids: [ID]) {
    subspecies(ids: $ids) {
      id
      name
    }
  }
`;

export const STAGES = gql`
  query Stages($subspeciesId: ID!) {
    stages(subspeciesId: $subspeciesId) {
      id
      name
    }
  }
`;

export const STAGESID = gql`
  query StagesIds($ids: [ID]) {
    stages(ids: $ids) {
      id
      name
    }
  }
`;
