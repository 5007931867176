import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { CHECK_AUTHENTICATION } from '../../../graphql';

const initialData = {
  logged: false,
  user: undefined,
  scope: undefined,
  loading: true,
};

const useAuthentication = () => {
  const client = useApolloClient();

  const [authentication, setAuthentication] = useState(initialData);

  useEffect(() => {
    const checkAuthentication = client.watchQuery({
      query: CHECK_AUTHENTICATION,
    });
    const subscribed = checkAuthentication.subscribe({
      next: ({ data: { checkAuthentication: auth }, loading }) => setAuthentication({
        logged: auth?.logged,
        user: auth?.user,
        scope: auth?.scope,
        loading,
      }),
    });

    return () => subscribed._cleanup();
  }, [client]);

  return {
    ...(authentication || initialData),
  };
};

export default useAuthentication;
