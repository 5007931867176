import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Form, Input, Modal, Switch
} from 'antd';
import { useTranslation } from 'react-i18next';
import { formFullLayout } from '../../config/Shared/Constants';
import Loading from './Loading';

const Review = ({
  handleSubmit,
  handleCancel,
  visible,
  review,
  loading,
  readonly,
  form,
}) => {
  const { t } = useTranslation();

  const { status, comment } = review || {};

  return (
    <Modal
      visible={visible}
      title={t('review.title')}
      okButtonProps={readonly ? { disabled: true } : { loading }}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      closable={false}
      maskClosable={false}
      onOk={() => handleSubmit(form)}
      onCancel={handleCancel}
      afterClose={form.resetFields}
    >
      <Loading loading={loading} custom="custom-component-spin">
        <Form>
          <p className="custom-text-left">{t('review.help')}</p>
          <Form.Item label={t('review.status')} {...formFullLayout}>
            {form.getFieldDecorator('status', {
              initialValue: status,
              valuePropName: 'checked',
            })(
              <Switch
                disabled={readonly}
                checkedChildren={t('common.yes')}
                unCheckedChildren={t('common.no')}
              />
            )}
          </Form.Item>
          <Form.Item label={t('review.comment')} {...formFullLayout}>
            {form.getFieldDecorator('comment', {
              initialValue: comment,
            })(
              <Input.TextArea
                disabled={readonly}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            )}
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  );
};

Review.propTypes = {
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  visible: PropTypes.bool,
  form: PropTypes.object,
  review: PropTypes.object,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default Form.create({ name: 'review' })(Review);
