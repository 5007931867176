import deburr from 'lodash.deburr';

const search = ({
  value, array, childrenProp, names
}) => {
  const filter = (result, record) => {
    if (record[childrenProp]) {
      const children = record[childrenProp].reduce(filter, []);

      if (children.length > 0) {
        return result.concat({ ...record, children });
      }
    }

    for (const name of names) {
      if (deburr(record[name]).match(new RegExp(deburr(value.replace(/[()]/g, '')), 'i'))) {
        return result.concat([record]);
      }
    }

    return result;
  };

  return array.reduce(filter, []);
};

export default search;
