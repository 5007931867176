import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import {
  Row, Col, Icon, Select, Input, Divider, Form
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { iconResolver, selectOptions } from '../../utils';
import { formValidation } from '../../utils/validations';
import { DOC_TYPES } from '../../graphql';
import Loading from './Loading';
import { formValidations } from '../../config/Shared/Constants';
import ActionButton from './ActionButton';

const Documents = ({
  files, readonly, handleDelete, handleUpdate, form
}) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(DOC_TYPES, {
    variables: { type: 'company' },
  });

  const handleChange = ({ value, id, name }) => {
    const docs = files;
    const index = docs.indexOf(docs.find((x) => x.file?.id === id));
    docs[index] = { ...docs[index], [name]: value };
    handleUpdate(docs);
  };

  return (
    <Form>
      {files?.map(({
        file, type, resolution, year
      }, index) => (
        <Fragment key={index}>
          <Row type="flex" gutter={8} key={index}>
            <Col span={7} className="custom-form-vertical-margin">
              <p className="custom-overflow-ellipsis">{file?.filename}</p>
            </Col>
            <Col span={1} className="custom-form-vertical-margin">
              <Icon type={iconResolver({ extension: file?.extension })} />
            </Col>
            <Col span={5}>
              <Loading loading={loading} custom="custom-component-spin">
                <Form.Item>
                  {form.getFieldDecorator(`${index}-type`, {
                    initialValue: type,
                    rules: [
                      { required: true, message: t('rules.fileTypeRequired') },
                    ],
                  })(
                    <Select
                showSearch
                optionFilterProp="children"
                placeholder={t('documents.fileType')}
                allowClear
                onChange={(value) => handleChange({ value, id: file?.id, name: 'type' })}
                onBlur={() => formValidation(form, [`${index}-type`])}
                disabled={readonly}
              >
                {selectOptions({
                        data,
                        list: 'docTypes',
                        value: 'key',
                        name: 'description',
                      })}
              </Select>
                  )}
                </Form.Item>
              </Loading>
            </Col>
            <Col span={5}>
              <Form.Item>
                {form.getFieldDecorator(`${index}-resolution`, {
                  initialValue: resolution,
                  rules: [
                    { required: true, message: t('rules.resolutionRequired') },
                  ],
                })(
                  <Input
                    maxLength={formValidations(t).max.input}
                    placeholder={t('documents.number')}
                    onChange={(e) => handleChange({
                value: e.target.value,
                id: file?.id,
                name: 'resolution',
              })}
                    onBlur={() => formValidation(form, [`${index}-resolution`])}
                    disabled={readonly}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                {form.getFieldDecorator(`${index}-year`, {
                  initialValue: year,
                  rules: [{ required: true, message: t('rules.yearRequired') }],
                })(
                  <Input
                    maxLength={formValidations(t).year}
                    placeholder={t('documents.year')}
                    onChange={(e) => handleChange({
                value: e.target.value,
                id: file?.id,
                name: 'year',
              })}
                    onBlur={() => formValidation(form, [`${index}-year`])}
                    disabled={readonly}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={1} className="custom-form-vertical-margin">
              <ActionButton
                key="delete"
                confirm
                confirmText={t('documents.confirmDelete')}
                handleClick={() => handleDelete(file?.id)}
                icon="delete"
                text={t('documents.delete')}
                type="danger"
                readonly={readonly}
              />
            </Col>
          </Row>
          <Divider className="custom-divider" />
        </Fragment>
      ))}
    </Form>
  );
};

Documents.propTypes = {
  readonly: PropTypes.bool,
  form: PropTypes.object,
  files: PropTypes.array,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
};

export default Form.create({ name: 'documents' })(Documents);
