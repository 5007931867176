import { gql } from 'apollo-boost';

export const TRANSFERIBLE_PRODUCTS = gql`
  query TransferibleProducts($companyId: ID!, $resolution: String!) {
    transferibleProducts(companyId: $companyId, resolution: $resolution) {
      id
      name
      resolution
      productType {
        id
        name
      }
      company {
        id
        name
      }
    }
  }
`;
