import { gql } from 'apollo-boost';

export const MEASURES = gql`
  query Measures($packing: Boolean) {
    measures(packing: $packing) {
      id
      description
      type
    }
  }
`;
