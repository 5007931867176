import React from 'react';
import * as PropTypes from 'prop-types';
import { Register, Activate, ForgotPassword } from '../../config/Shared/Result';

const ResultExtra = ({ type, history }) => {
  switch (type) {
    case 'register':
      return <Register history={history} />;
    case 'activate':
      return <Activate history={history} />;
    case 'reset':
    case 'forgotPassword':
      return <ForgotPassword history={history} />;
    default:
      return <div />;
  }
};

ResultExtra.propTypes = {
  type: PropTypes.string,
  history: PropTypes.object,
};

export default ResultExtra;
