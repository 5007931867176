import React from 'react';
import * as PropTypes from 'prop-types';
import { Layout as AntLayout, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';
import MenuPrimary from './MenuPrimary';
import User from './User';
import footer from '../../../img/ica-footer.png';
import CompanySelector from './CompanySelector';
import { InputTooltip } from '../../Shared';

const Layout = ({
  path,
  pathname,
  menus,
  headerMenus,
  scope,
  user,
  collapsed,
  handleCollapse,
  handleScope,
  children,
  history,
}) => {
  const { t } = useTranslation();

  return (
    <AntLayout className="custom-layout">
      <AntLayout.Header className="custom-header">
        <Row>
          <Col span={4}>
            <Logo />
          </Col>
          <Col span={4}>
            <CompanySelector
              pathname={path}
              history={history}
              scope={scope}
              handleScope={handleScope}
            />
          </Col>
          <Col span={1}>
            <InputTooltip
              icon="info-circle"
              text={t('company.selectCompany')}
              className="custom-tooltip"
            />
          </Col>
          <Col offset={9} span={6}>
            <User user={user} menus={headerMenus} history={history} />
          </Col>
        </Row>
      </AntLayout.Header>
      <AntLayout.Content>
        <AntLayout className="custom-content-layout">
          <AntLayout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={handleCollapse}
            className="custom-layout-sider"
          >
            <MenuPrimary
              history={history}
              pathname={pathname}
              menus={menus}
              scope={scope}
              user={user}
            />
          </AntLayout.Sider>
          <AntLayout.Content className="custom-content">
            {children}
          </AntLayout.Content>
        </AntLayout>
      </AntLayout.Content>
      <AntLayout.Footer>
        <img className="custom-layout-footer" src={footer} alt="ICA footer" />
        <p className="footer-text">{process.env.REACT_APP_VERSION}</p>
      </AntLayout.Footer>
    </AntLayout>
  );
};

Layout.propTypes = {
  collapsed: PropTypes.bool,
  path: PropTypes.string,
  pathname: PropTypes.string,
  history: PropTypes.object,
  scope: PropTypes.object,
  user: PropTypes.object,
  menus: PropTypes.array,
  headerMenus: PropTypes.array,
  handleCollapse: PropTypes.func,
  handleScope: PropTypes.func,
  children: PropTypes.any,
};

export default Layout;
