import React from 'react';
import * as PropTypes from 'prop-types';
import { Col, Progress, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Container from './Container';
import Title from './Title';

const StatusRequest = (
  {
    title,
    percent,
    status,
    idRequest,
    statusRequest,
    commentRequest
  }
) => {
  const { t } = useTranslation();

  return (
    <Container className="custom-status-bar-container">
      <Title title={title || t('request.detail')} />
      <Progress percent={percent} status={status} />
      <Row style={{ paddingTop: '20px' }}>
        <Col span={6}>
          <p>Id de la solicitud</p>
          <p><strong>{idRequest}</strong></p>
        </Col>
        <Col span={6}>
          <p>Estado de la solicitud</p>
          <strong>{statusRequest}</strong>
        </Col>
        <Col span={6}>
          {commentRequest && (
            <>
              <p>Justificación</p>
              <strong>{commentRequest}</strong>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

StatusRequest.protoTypes = {
  percent: PropTypes.number,
  title: PropTypes.string,
  idRequest: PropTypes.number,
  statusRequest: PropTypes.string,
  commentRequest: PropTypes.string
};

export default StatusRequest;
